define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "api-error-messages": {
      "bad-request-error": "The data entered was not in the correct format.",
      "internal-server-error": "An error occurred, our teams are working on finding a solution. Please try again later.",
      "join-error": {
        "r11": "{ value }",
        "r12": "You already have a Unisciel account under the username '<br>'{ value }.'<br>'To continue, log in with this account or ask for help from a teacher.'<br>'(Code R12)",
        "r13": "You already have a Unisciel account through your virtual learning environment (\"ENT\") in another school '<br> 'To continue, contact a teacher who can give you access to this account using Unisciel Orga.",
        "r31": "{ value }",
        "r32": "You already have a Unisciel account under the username '<br>'{ value }.'<br>'To continue, log in with this account or ask for help from a teacher.'<br>'(Code R32)",
        "r33": "You already have a Unisciel account through your virtual learning environment (\"ENT\"). Log in with this account to take your customised test.",
        "r70": "An error occurred. Please log out and try again."
      },
      "login-unauthorized-error": "There was an error in the email address or username/password entered.",
      "register-error": {
        "s51": "{ value }",
        "s52": "You already have a Unisciel account under the username '<br>'{ value }.'<br>'To continue, log in with this account or ask for help from a teacher.'<br>'(Code S52)",
        "s53": "You already have a Unisciel account through your virtual learning environment (\"ENT\"). Log in with this account to take your customised test.",
        "s61": "{ value }",
        "s62": "You already have a Unisciel account under the username '<br>'{ value }.'<br>'To continue, log in with this account or ask for help from a teacher.'<br>'(Code S62)",
        "s63": "You already have a Unisciel account through your virtual learning environment (\"ENT\") in another school '<br> 'To continue, contact a teacher who can give you access to this account using Unisciel Orga.'<br>'(Code S63)"
      }
    },
    "application": {
      "description": "Unisciel is an online platform open to all (pupils, students, anyone working or in professional training, seniors) designed to assess, develop and certify digital skills"
    },
    "common": {
      "actions": {
        "back": "Back",
        "cancel": "Cancel",
        "close": "Close",
        "quit": "Exit",
        "sign-out": "Sign out"
      },
      "anr-credits": "Le projet Unisciel bénéficie d’un soutien financier de l’Agence Nationale de la Recherche (ANR), sous le n°ANR-17-NCUN-0015. ",
      "ecriplus": "Unisciel",
      "ecriplus-logo": "logo Unisciel",
      "form": {
        "error": "error",
        "invisible-password": "Hide password",
        "mandatory": "required",
        "mandatory-all-fields": "All fields are required.",
        "mandatory-fields": "The fields marked '<abbr title=\"required\" class=\"mandatory-mark\">'*'</abbr>' are required",
        "success": "success",
        "visible-password": "Show password"
      },
      "french-republic": "French Republic",
      "level": "level",
      "loading": {
        "default": "Loading",
        "results": "Preparing your results",
        "test": "Your test is loading"
      },
      "marianne-logo": "Logo of Marianne",
      "or": "or",
      "pix": "Unisciel",
      "score": "points",
      "skip-links": {
        "skip-to-content": "Skip to main content",
        "skip-to-footer": "Skip to footer"
      }
    },
    "current-lang": "en",
    "navigation": {
      "anr-logo": "logo anr",
      "back-to-homepage": "Return to homepage",
      "back-to-profile": "Return to profile",
      "copyrights": "©",
      "error": "Error",
      "footer": {
        "a11y": "Accessibility",
        "data-protection-policy": "Personal data protection policy",
        "eula": "Terms and conditions",
        "help-center": "Help center",
        "legal-notices": "Legal notices",
        "sitemap": "Site map"
      },
      "homepage": "Unisciel's Homepage",
      "main": {
        "code": "I have a code",
        "dashboard": "Home",
        "discover": "MaPS discover",
        "discover-faq": "F.A.Q",
        "help": "Help",
        "link-help": "https://www.unisciel.fr/",
        "skills": "Competences",
        "start-certification": "Certification",
        "tutorials": "My tutorials"
      },
      "not-logged": {
        "sign-in": "Log in",
        "sign-up": "Sign up"
      },
      "pix": "Unisciel",
      "user": {
        "account": "My account",
        "certifications": "My certifications",
        "sign-out": "Log out",
        "tests": "My customised tests"
      },
      "user-logged-menu": {
        "details": "Check my details"
      }
    },
    "pages": {
      "account-recovery": {
        "errors": {
          "account-exists": "Un compte existe déjà avec cette adresse e-mail.",
          "key-expired": "Le lien de récupération est expiré, ",
          "key-expired-renew-demand-link": "renouvelez votre demande ici.",
          "key-invalid": "Le lien de récupération n’existe pas.",
          "key-used": "Ce compte a déjà été récupéré.",
          "title": "Oups, une erreur est survenue !"
        },
        "find-sco-record": {
          "backup-email-confirmation": {
            "ask-for-new-email-message": "sinon fournissez en une nouvelle",
            "email-already-exist-for-account-message": "L’adresse e-mail ci-dessous est déjà associée à votre compte :",
            "email-is-needed-message": "{ firstName }, nous avons besoin de votre adresse e-mail",
            "email-is-valid-message": "Si elle est valide,",
            "email-reset-message": "réinitialisez votre mot de passe",
            "email-sent-to-choose-password-message": "Nous vous enverrons un e-mail afin de choisir un mot de passe.",
            "form": {
              "actions": {
                "cancel": "Annuler",
                "submit": "C'est parti"
              },
              "email": "Votre adresse e-mail",
              "error": {
                "email-already-exist": {
                  "existing-email": "Vous possédez déjà cette adresse e-mail. Si elle est valide,",
                  "init-password": " réinitialisez votre mot de passe",
                  "new-email": ", sinon saisissez une nouvelle."
                },
                "empty-email": "Le champ adresse e-mail est obligatoire.",
                "new-backup-email": "Veuillez saisir un e-mail valide pour récupérer votre compte",
                "new-email-already-exist": "Cette adresse e-mail est déjà utilisée",
                "wrong-email-format": "Votre adresse e-mail n’est pas valide."
              }
            }
          },
          "confirmation-step": {
            "buttons": {
              "cancel": "Annuler",
              "confirm": "Je confirme"
            },
            "certify-account": "J’atteste sur l’honneur que le compte associé à ces données m’appartient.",
            "contact-support": "Si vous constatez une erreur ou si ces données ne sont pas les vôtres, ",
            "fields": {
              "first-name": "Votre prénom",
              "last-name": "Votre nom",
              "latest-organization-name": "Votre dernier établissement",
              "username": "Votre identifiant"
            },
            "found-account": "Nous avons trouvé votre compte :",
            "good-news": "Bonne nouvelle { firstName } !"
          },
          "conflict": {
            "found-you-but": "{ firstName }, on vous a retrouvé mais ...",
            "title": "Conflit",
            "warning": "Par précaution nous devons vérifier vos données ensemble."
          },
          "contact-support": {
            "link-text": "contactez le support.",
            "link-url": "https://www.unisciel.fr"
          },
          "send-email-confirmation": {
            "check-spam": "Si vous ne voyez pas cet e-mail, vérifiez vos courriers indésirables.",
            "return": "Retour",
            "send-email": "Nous venons de vous adresser un mail qui vous permettra de choisir un mot de passe. Vous pouvez consulter dès maintenant votre messagerie.",
            "title": "Récupération de votre compte Unisciel"
          },
          "student-information": {
            "errors": {
              "empty-first-name": "Le champ prénom est obligatoire.",
              "empty-ine-ina": "Le champ INE est obligatoire.",
              "empty-last-name": "Le champ nom est obligatoire.",
              "invalid-ine-ina-format": "Le champ INE n'est pas au bon format.",
              "not-found": "Unisciel ne reconnait pas vos données. Vérifiez qu'elles sont exactes, sinon "
            },
            "form": {
              "birthdate": "Date de naissance",
              "first-name": "Prénom",
              "ine-ina": "INE (Identifiant National Élève)",
              "label": {
                "birth-day": "jour de naissance",
                "birth-month": "mois de naissance",
                "birth-year": "année de naissance"
              },
              "last-name": "Nom",
              "placeholder": {
                "birth-day": "JJ",
                "birth-month": "MM",
                "birth-year": "AAAA"
              },
              "submit": "Retrouvez-moi !",
              "tooltip": "<strong>Où trouver mon INE ?</strong> <p>Cet identifiant se trouve le plus souvent sur le bulletin scolaire, sinon votre ancien établissement peut vous le fournir.</p>"
            },
            "mandatory-all-fields": "Tous les champs sont obligatoires.",
            "subtitle": {
              "link": "réinitialisez votre mot de passe ici.",
              "text": "Si vous possédez un compte avec une adresse e-mail valide, "
            },
            "title": "Vous avez quitté le système scolaire et vous souhaitez récupérer votre accès à Unisciel"
          },
          "title": "Récupérer mon compte"
        },
        "support": {
          "recover": " pour obtenir une assistance.",
          "url": "https://www.unisciel.fr",
          "url-text": "Contactez le support"
        },
        "update-sco-record": {
          "fill-password": "Saisissez un mot de passe et Unisciel est à vous !",
          "form": {
            "email-label": "Adresse e-mail",
            "errors": {
              "empty-password": "Le champ mot de passe est obligatoire.",
              "invalid-password": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre."
            },
            "login-button": "Je me connecte",
            "password-label": "Mot de passe"
          },
          "welcome-message": "Bon retour parmi nous { firstName } !"
        }
      },
      "assessment-banner": {
        "title": "Question for the assessment: "
      },
      "assessment-results": {
        "actions": {
          "continue-pix-experience": "Continue my Unisciel experience",
          "return-to-homepage": "Return to homepage"
        },
        "answers": {
          "header": "Your answers"
        },
        "title": "End of test"
      },
      "campaign": {
        "errors": {
          "no-longer-accessible": "Oops, the requested page is not available.",
          "not-accessible": "Oops, the requested page is not available."
        }
      },
      "campaign-landing": {
        "assessment": {
          "action": "Begin",
          "announcement": "Start your customised test.'<br>' Sign up or log in to the Unisciel platform and start your test.",
          "certify": {
            "description": "The Unisciel Certification enables you to highlight your digital skills to employers.",
            "title": "Prepare a certification recognised throughout Europe and in the business world"
          },
          "details": "During this customised test, you will be able to:",
          "develop": {
            "description": "Every 5 questions, you will be able to see your results and watch tutorials to help you improve your skills.",
            "title": "See your results as you go along and view tips to improve."
          },
          "evaluate": {
            "complement": "The Unisciel public service for the assessment and certification of digital skills enables assessment of your level in 5 major competence areas covering current uses of digital technology.",
            "list": "'<ul class=\"campaign-landing-page__details__text article-list\"><li>'finding information on the internet,'</li><li>'producing documents,'</li><li>'communicating and collaborating online,'</li><li>'securing your work environment,'</li><li>'etc.'</li></ul>'",
            "more-info": {
              "label": "Find out more.",
              "link": "https://ecriplus.fr/descriptif-de-competences"
            },
            "title": "Assess your digital skills with fun challenges on different subjects, such as:"
          },
          "legal": "By clicking on “Begin“, information about your progress will be shared with the organiser so they can assist you if necessary. At the end of your customised test, your results will be shared with your organisation when clicking on “Submit my results“.",
          "title": "Begin your customised test"
        },
        "profiles-collection": {
          "action": "Let’s go!",
          "announcement": "Sign up or log in to the Unisciel platform and send your profile to the recipient organisation.",
          "legal": "Information about your progress will be sent to the organiser so they can help you if necessary. It will only be sent with your permission.",
          "step-one": {
            "evaluation-instruction-title": "Consigne d’évaluation",
            "evaluation-instruction-title-mobile": "Consigne",
            "privacy-policy": "politique de confidentialité",
            "service-end": " d’Unisciel",
            "service-middle": " et la ",
            "service-start": "Vous pouvez consulter, ",
            "use-condition": "condition d’utilisation"
          },
          "stepper": {
            "step-one": "1- Je démarre",
            "step-three": "3- J’envoie",
            "step-two": "2- Je m’identifie <br/><span>(Optionnel)</span>"
          },
          "title": "Submit your profile"
        },
        "title": "Presentation"
      },
      "campaign-participation": {
        "title": "Customised test"
      },
      "campaign-participation-overview": {
        "card": {
          "finished-at": "Finished the {date}",
          "results": "Success rate: {result, number, ::percent}",
          "resume": "Resume",
          "see-more": "See more",
          "send": "Submit my results",
          "stages": "{count} { count, plural, =0 {star} =1 {star} other {stars} } out of {total}",
          "started-at": "Started the {date}",
          "tag": {
            "archived": "Archived",
            "completed": "To be submitted",
            "finished": "Finished",
            "started": "In progress"
          },
          "text-archived": "Your customised test has been archived by your organisation.'<br>'Your results could not be submitted."
        },
        "title": "Customised test"
      },
      "certificate": {
        "attestation": "Download my certificate of achievement",
        "back-link": "Return to my certificates",
        "candidate-birth": "Born on {birthdate}",
        "candidate-birth-complete": "Born on {birthdate} in {birthplace}",
        "certification-center": "Certification centre:",
        "clea": {
          "alternative": "Digital CléA certification"
        },
        "competences": {
          "information": "Your certified score was calculated based on the answers you gave whilst taking the certification. It may be different from the score shown on your profile. When you took the certification, it was possible to reach a maximum of level {maxReachableLevelOnCertificationDate} in each competence and {maxReachablePixCountOnCertificationDate} Unisciel points.",
          "subtitle": "(levels out of {maxReachableLevel})",
          "title": "Certified competences"
        },
        "complementary": {
          "alternative": "Additional certification",
          "title": "Other certification(s) awarded"
        },
        "exam-date": "Completion date:",
        "hexagon-score": {
          "certified": "certified"
        },
        "issued-on": "Delivered on",
        "jury-info": "Notes from the examining body are not displayed on the verification page of your certificate.",
        "jury-title": "Notes from the examining body",
        "title": "Unisciel Certificate",
        "validity": "Certificate valid for 3 years",
        "verification-code": {
          "alt": "Copy",
          "copied": "Copied!",
          "copy": "Copy the code",
          "info": "(?)",
          "title": "Verification code",
          "tooltip": "Communicate this code to allow a third party to verify the authenticity of your certificate"
        }
      },
      "certification-joiner": {
        "congratulation-banner": {
          "clea-eligibility": "You are also eligible for the CléA numérique certification.",
          "link": {
            "text": "How do I certify my digital skills?",
            "url": "https://www.unisciel.fr"
          },
          "message": "Well done, {fullName},'<br>'your profile can now be certified.",
          "pix-plus-droit-expert-eligibility": "You are also eligible for the Unisciel Droit Expert certification.",
          "pix-plus-droit-maitre-eligibility": "You are also eligible for the Unisciel Droit Maître certification."
        },
        "error-messages": {
          "generic": "Oops! We can't find you.\nPlease check your details to continue or call the invigilator.",
          "session-not-accessible": "The session you are trying to join is no longer available.",
          "wrong-account": "Oops! It seems that you are using the wrong Unisciel account to join this certification session.\nTo continue, please login to the correct Unisciel account or ask the invigilator for help."
        },
        "first-title": "Join a session",
        "form": {
          "actions": {
            "submit": "Continue"
          },
          "fields": {
            "birth-date": "Date of birth",
            "birth-day": "Day (DD)",
            "birth-month": "Month (MM)",
            "birth-name": "Given name",
            "birth-year": "Year (YYYY)",
            "first-name": "First name",
            "session-number": "Session number"
          },
          "fields-validation": {
            "session-number-error": "The session ID must only contain numbers."
          }
        },
        "title": "Join a certification session"
      },
      "certification-not-certifiable": {
        "action": {
          "back": "Return to homepage"
        },
        "text": "To have your profile certified, you need to have reached at least level 1 in 5 competences.",
        "title": "Your profile cannot be certified yet."
      },
      "certification-results": {
        "action": {
          "confirm": "Confirm",
          "logout": "Log out"
        },
        "finished": {
          "description": "Your results will be available in your account shortly.",
          "title": "Well done, you’ve finished!",
          "warning-text": "If you are not using your own computer, remember to log out."
        },
        "flag-alt": "Flag",
        "not-finished": {
          "supervisor": "Call the invigilator: '<br>'They must see this screen to be able to finalise your certification.",
          "title": "You’ve almost finished",
          "warning-text": "The invigilator has seen this screen."
        },
        "title": "Certification progress"
      },
      "certification-start": {
        "access-code": "Enter the access code sent by the invigilator",
        "actions": {
          "submit": "Start my test"
        },
        "cgu": {
          "contact": {
            "email": "dpo@ecriplus.fr",
            "info": "In accordance with the French law governing computer technology and freedoms (“Informatique et Libertés”), you can exercise your right to access and rectify your data by emailing "
          },
          "info": "By clicking on “Start my test”, I agree to my identifying data, certification number and the circumstances in which the test was taken, as reported by the invigilator, being sent to Unisciel. Unisciel will use this information during the board of examiners’ deliberation to produce and archive my results and create my certificate. If this certification was ordered by an organisation, I agree to Unisciel sharing my results with them."
        },
        "error-messages": {
          "access-code-error": "This code does not exist or is no longer valid.",
          "generic": "An unexpected server error has occurred.",
          "session-not-accessible": "The certification session is no longer available."
        },
        "first-title": "You are about to begin your certification test.",
        "title": "Join a certification session"
      },
      "certifications-list": {
        "header": {
          "certification-center": "certification centre",
          "date": "date",
          "score": "Unisciel score",
          "status": "status"
        },
        "no-certification": {
          "text": "You have no certifications yet."
        },
        "statuses": {
          "cancelled": {
            "title": "Certification cancelled"
          },
          "fail": {
            "action": "details",
            "title": "Certification not awarded"
          },
          "not-published": {
            "title": "Pending results"
          },
          "success": {
            "action": "see results",
            "title": "Certification awarded"
          }
        },
        "title": "My Certifications"
      },
      "challenge": {
        "actions": {
          "continue": "Continue",
          "go-to-next": " and go to the next question",
          "skip": "Skip",
          "validate": "Validate"
        },
        "already-answered": "You have already answered this question",
        "answer-input": {
          "numbered-label": "Answer {number}"
        },
        "certification": {
          "banner": {
            "certification-number": "Certification no."
          },
          "feedback-panel": {
            "certification-number": "your certification no. (in the top-right of the screen)",
            "description": "To report a problem, please call your invigilator and provide the following information:",
            "problem": "the problem encountered",
            "question-number": "the question number (in the top-right of the screen)"
          },
          "title": "Certification {certificationNumber}"
        },
        "embed-simulator": {
          "actions": {
            "launch": "Launch the app",
            "reset": "Restart"
          },
          "placeholder": "App loading"
        },
        "feedback-panel": {
          "actions": {
            "open-close": "Report a problem"
          },
          "description": "Unisciel wants to hear from you so we can improve our tests!*",
          "email-body": "%0D%0A%0D%0A%0D%0ADo not remove:%0D%0AReference= ",
          "form": {
            "actions": {
              "submit": "Send"
            },
            "fields": {
              "category-selection": {
                "label": "I am experiencing difficulties with",
                "options": {
                  "accessibility": "Test accessibility",
                  "answer": "The answer",
                  "download": "The file to download",
                  "embed": "The simulator/app",
                  "link": "The link in the question",
                  "other": "Other",
                  "picture": "The image",
                  "question": "The question",
                  "tutorial": "The tutorial"
                }
              },
              "detail-selection": {
                "aria-first": "You have a problem with",
                "aria-secondary": "Specify your problem",
                "label": "Please specify",
                "options": {
                  "answer-not-accepted": "My answer is correct but has not been approved",
                  "answer-not-agreed": "I disagree with the answer",
                  "download": {
                    "edit-failure": {
                      "label": "I cannot edit the file",
                      "solution": "The file is probably open in “Read only” or “Protected mode”.'<br>'Click on “Activate editing” or “Edit document” in the banner at the top of the file if this is shown. '<br>'Otherwise save the file under another name and try opening file again from here."
                    },
                    "lost": {
                      "label": "I cannot find the downloaded file",
                      "solution": "By default, files you download will be saved in your “Downloads” folder. It may also have been saved to the same location as your last download..."
                    },
                    "open-failure": {
                      "label": "I cannot open the file on a computer",
                      "solution": "To answer this question, you need to use the LibreOffice suite, which is available free-of-charge for PC and Mac. It contains Libre Office Writer (equivalent to Word) and Libre Office Calc (equivalent to Excel).'<a href=\"https://www.libreoffice.org/download/download\">'Download Libre Office'</a>'"
                    },
                    "other": "I have another problem with the file"
                  },
                  "embed-displayed-on-desktop-with-problems": {
                    "label": "On a computer, the simulator is displayed but has a problem",
                    "solution": "We are doing our best to make the simulators work on all devices, all operating systems, and all browsers but you may be using a specific system.'<br>'You can stop your test and resume on a computer. Please specify your problem, as well as your browser and your operating system."
                  },
                  "embed-displayed-on-mobile-devices-with-problems": {
                    "label": "On a smartphone or a tablet, the simulator is displayed but has a problem",
                    "solution": "We are doing our best to make the simulators work on all devices, all operating systems, and all browsers but you may be using a specific system.'<br>'Please specify your problem, as well as your type of device (smartphone, tablet etc.), your browser and your operating system."
                  },
                  "embed-not-displayed": {
                    "label": "I cannot see the simulator/app",
                    "solution": "Your internet connection may not be strong enough.'<br>'Refresh the page by clicking on the refresh button'<img class=\"tuto-icon\" src=\"/images/icons/icon-reload.svg\" alt=\"\" />' next to the address bar. Wait a little while, the simulator might display. '<br><br>'If it doesn’t, you can try again later or from another location with a better connection."
                  },
                  "embed-other": "There is another problem with the simulator/app",
                  "link-other": "The link doesn’t work or has another problem",
                  "link-unauthorized": {
                    "label": "The link has been blocked by my organisation/school…",
                    "solution": "You may belong to an organisation (schools, universities, companies, administrations, associations, etc.) that protects its users and devices by limiting the Internet pages you can access."
                  },
                  "other-challenge-proposal": "I have a (great) idea to suggest for a question",
                  "other-difficulty": "I have another problem",
                  "other-site-improvement": "I have a suggestion for improving the platform",
                  "picture-not-displayed": {
                    "label": "The picture isn't displayed.",
                    "solution": "Your internet connexion may be too slow.'<br>'Reload the page by clicking on the refresh button '<img class=\"tuto-icon\" src=\"/images/icons/icon-reload.svg\" alt=\"\" />' next to the address bar. Wait for a bit, the picture may be displayed. '<br><br>'If this isn't the case, please try again later or from somewhere with a better connection."
                  },
                  "picture-other": "There is another problem with the image.",
                  "question-improvement": "I want to suggest an improvement to the question",
                  "question-not-understood": "I do not understand the question",
                  "tutorial-link-error": "The link to the tutorial is to another page or an error page",
                  "tutorial-not-accepted": "The tutorial is not helpful to the question",
                  "tutorial-proposal": "I have a tutorial to suggest"
                },
                "problem-suggestion-description": "Describe your problem or suggestion"
              }
            },
            "status": {
              "error": {
                "empty-message": "You must enter a message.",
                "max-characters": "Your message cannot exceed 10,000 characters."
              },
              "success": "'<p>'Your comment has been sent to the Unisciel project team.'</p><p>'Thank you!'</p>'"
            }
          },
          "information": {
            "data-usage": "'<p>'Unisciel processes the data from this area to manage and analyse the difficulty encountered and benefit from your feedback. You have rights over your data which can be exercised via '<a href=\"mailto:dpo@ecriplus.fr\" class=\"link\">'dpo@ecriplus.fr'</a>'.'</p><p><a href=\"https://www.unisciel.fr\" target=\"_blank\" class=\"link\">'To find out more about protecting your data and your rights.'</a></p>'",
            "guidance": "'<p>'*Make sure you write in this zone: for your benefit and the benefit of others, please stay objective and keep to the facts.'</p><p>'Do not enter any information about yourself or third parties, or any information related to health; religion; political, or philosophical opinions; trade union affiliation; ethnic origins; or penalties and convictions.'</p>'"
          },
          "support-email": "support@ecriplus.fr"
        },
        "has-focused-out-of-window": {
          "certification": "We have detected a page switch.'<br>'Your answer will not be validated.",
          "default": "We have detected a page switch.'<br>'During a certification test, your answer would not be validated."
        },
        "illustration": {
          "placeholder": "Image loading"
        },
        "is-focused-challenge": {
          "info-alert": {
            "subtitle": "If you switch pages or tabs during a certification test, you answer will not be validated.",
            "title": "Answer this question without searching the internet or using any piece of software."
          }
        },
        "parts": {
          "answer-input": "Your answer",
          "feedback": "Report a problem",
          "instruction": "Instructions to answer the question",
          "progress": "Your progression",
          "validation": "Validate or skip this question"
        },
        "progress-bar": {
          "label": "Question"
        },
        "skip-error-message": {
          "qcm": "To validate, select at least one answer. Otherwise, click Skip.",
          "qcu": "To validate, select an answer. Otherwise, click Skip.",
          "qroc": "To validate, please fill in the text field. Otherwise, click Skip.",
          "qroc-auto-reply": "You did not answer correctly. Try again or skip to the next question.",
          "qroc-number": "Please enter a number, or skip the question.",
          "qrocm": "To validate, please complete all answer fields. Otherwise, click Skip."
        },
        "statement": {
          "alternative-instruction": {
            "actions": {
              "display": "Show alternative instruction",
              "hide": "Hide alternative instruction"
            }
          },
          "external-link-title": "New window",
          "file-download": {
            "actions": {
              "choose-type": "Select the type of file you would like to use",
              "download": "Download"
            },
            "description": "Unisciel lets you choose which format of file to download. If you do not know which option to use, select the default option. This is the file format that is the most commonly used.",
            "file-type": "file .{fileExtension}",
            "help": "Need help to '<a href=\"https://www.unisciel.fr\" class=\"challenge-statement__action-help--link\" target=\"_blank\">'open, edit or find this file'</a>'?"
          },
          "tooltip": {
            "aria-label": "Show question's warning",
            "close": "Ok, got it",
            "focused": {
              "content": "<p class=\"tooltip-tag-information__title--focused\">Stay on this page to answer!</p> Do not use the internet or any application to answer. Any activity outside this zone will be detected.",
              "title": "Focus Mode"
            },
            "other": {
              "content": "To answer this question, feel free to search the internet or use an external application.",
              "title": "Free Mode"
            }
          }
        },
        "timed": {
          "cannot-answer": "You've run out of time. Your answer will not be validated."
        },
        "title": {
          "default": "Free Mode - Question {stepNumber} of {totalChallengeNumber}",
          "focused": "Focus Mode - Question {stepNumber} of {totalChallengeNumber}",
          "focused-out": "Failed - Focus Mode - Question {stepNumber} of {totalChallengeNumber}",
          "timed-out": "Timed out - Question {stepNumber} of {totalChallengeNumber}"
        }
      },
      "checkpoint": {
        "actions": {
          "next-page": {
            "continue": "Continue",
            "results": "See my results"
          }
        },
        "answers": {
          "already-finished": {
            "explanation": "You have already answered the questions in your previous customised tests. You can go straight to your results.",
            "info": "Don’t be surprised, it’s over already!"
          },
          "header": "your answers"
        },
        "completion-percentage": {
          "caption": "progression",
          "label": "'<p class=\"sr-only\">'You have completed '</p>'{completionPercentage}%'<p class=\"sr-only\">' of your customised test.'</p>'"
        },
        "title": {
          "assessment-progress": "Progression",
          "end-of-assessment": "End of your assessment"
        }
      },
      "comparison-window": {
        "results": {
          "a11y": {
            "given-answer": "given answer",
            "the-answer-was": "The answer was"
          },
          "aband": {
            "title": "You did not answer",
            "tooltip": "No answer"
          },
          "abandAutoReply": {
            "title": "You have skipped the question",
            "tooltip": "Question skipped"
          },
          "default": {
            "title": "Answer",
            "tooltip": "Auto-correct under development ;)"
          },
          "feedback": {
            "correct": "Correct answer",
            "wrong": "Incorrect answer.'<br>'The correct answer is: "
          },
          "focusedOut": {
            "title": "You lost focus",
            "tooltip": "Question focused out"
          },
          "ko": {
            "title": "That’s not the right answer",
            "tooltip": "Incorrect answer"
          },
          "koAutoReply": {
            "title": "You did not correctly answer the question",
            "tooltip": "Question answered incorrectly"
          },
          "ok": {
            "title": "You got the right answer!",
            "tooltip": "Correct answer"
          },
          "okAutoReply": {
            "title": "You correctly answered the question",
            "tooltip": "Question answered correctly "
          },
          "partially": {
            "title": "You gave an incomplete answer",
            "tooltip": "Incomplete answer"
          },
          "timedout": {
            "title": "You've run out of time",
            "tooltip": "Out of time"
          },
          "tips": {
            "other-proposition": "Other proposition",
            "your-answer": "Your answer"
          }
        },
        "upcoming-tutorials": "You will soon find tutorials here to help you answer correctly to this type of question!!"
      },
      "competence-details": {
        "actions": {
          "continue": {
            "label": "Resume"
          },
          "improve": {
            "description": {
              "countdown": "{ daysBeforeImproving, plural, =0 {0 day.} =1 {1 day.} other {# days.} }",
              "waiting-text": "Try the next level in\n"
            },
            "improvingText": "Try the next level!",
            "label": "Try again"
          },
          "reset": {
            "description": "Reset available in \n { daysBeforeReset, plural, =0 {0 day.} =1 {1 day.} other {# days.} }",
            "label": "Reset",
            "modal": {
              "important-message": "Your Unisciel points { earnedPix } will be deleted.",
              "important-message-above-level-one": "Your level { level } and your Unisciel points { earnedPix } will be deleted.",
              "title": "Competence reset",
              "warning": {
                "certification": "If you are planning to certify your profile and results, this could affect your certification.",
                "header": "NB: ",
                "ongoing-assessment": "If you have a customised test in progress, you might be asked some questions again."
              }
            }
          },
          "start": {
            "label": "Start"
          }
        },
        "for-competence": "the competence {competence}",
        "next-level-info": "{ remainingPixToNextLevel } Unisciel points to level { level }",
        "title": "Competence",
        "tutorials": {
          "description": "Here are a selection of tutorials that will help you increase your Unisciel score.",
          "title": "Develop your skills"
        }
      },
      "competence-result": {
        "header": {
          "congratulations": "Congratulations!",
          "not-bad": "Not bad, but could be better!",
          "not-bad-subtitle": "A little more effort and you’ll reach the first level.",
          "too-bad": "Never mind!",
          "too-bad-subtitle": "It doesn’t seem to be your day, but you’ll do better next time.",
          "you-have-earned": "You have",
          "you-have-reached-level": "You reached"
        },
        "title": "Results"
      },
      "dashboard": {
        "campaigns": {
          "resume": {
            "action": "Resume",
            "text": "<h2>Don’t forget to submit your results!</h2>"
          },
          "subtitle": "Resume your customised tests or submit your results",
          "tests-link": "All my customised tests",
          "title": "Customised tests"
        },
        "empty-dashboard": {
          "link-to-competences": "See my skills",
          "message": "<h2>Congrats! You've finished all the competences recommended for you!</h2> <p> Want to continue your Unisciel experience? You can try a competence again. </p>"
        },
        "improvable-competences": {
          "profile-link": "See all",
          "subtitle": "Ready to improve your score?",
          "title": "Try again"
        },
        "presentation": {
          "link": {
            "text": "",
            "url": ""
          },
          "message": "<h2>Hi {firstname}, welcome to your dashboard.</h2><p>Quickly and easily access all your tests in progress.<br>Follow the evolution of your Unisciel score and check if your profile is ready for certification.</p>"
        },
        "recommended-competences": {
          "profile-link": "See all competences",
          "subtitle": "Discover the competences recommended for you.",
          "title": "Recommended"
        },
        "score": {
          "profile-link": "See my competences"
        },
        "started-competences": {
          "subtitle": "Resume testing your skills, find your most recent tests here.",
          "title": "In progress"
        },
        "title": "Homepage"
      },
      "error": {
        "content-text": "'<p>'Please refresh the page or '<LinkTo @route=\"login\">'return to homepage'</LinkTo>'.'</p><p>'You can also contact us via '<a href=\"https://www.unisciel.fr\">'the help centre form'</a>'specifying the error code below in the description.'</p><p>'Please excuse any inconvenience caused.'</p><p>'The Unisciel team.'</p>'",
        "first-title": "Oops, an error occurred!",
        "send-report": "Send error report"
      },
      "explication-panel": {
        "title": "Explications"
      },
      "fill-in-campaign-code": {
        "description": "This code can be used to start a customised test'<br>'or to submit your profile to an organisation",
        "errors": {
          "forbidden": "Oops! We can’t find you. Check your details to continue or contact the organiser.",
          "missing-code": "Please enter a code.",
          "not-found": "Your code is incorrect. Please check it or contact the organiser."
        },
        "faq": "Qu’est ce qu’un Code parcours et comment l’utiliser ?",
        "first-title-connected": "{ firstName }, enter your code",
        "first-title-not-connected": "Enter your code",
        "start": "Start",
        "title": "I have a code",
        "warning-message": "If you are not <span>{ firstName } { lastName }<span/>,",
        "warning-message-logout": "please log out"
      },
      "fill-in-certificate-verification-code": {
        "description": "The Unisciel Certification assesses digital literacy and is recognised throughout Europe and in the business world.",
        "errors": {
          "missing-code": "Please enter the verification code in this format: P-XXXXXXX",
          "not-found": "There is no corresponding Unisciel Certificate.",
          "unallowed-access": "Please fill out the field above with the verification code in this format: P-XXXXXXX",
          "wrong-format": "Please check your verification code"
        },
        "first-title": "Verify the score of a Unisciel Certificate",
        "label": "Verification code (P-XXXXXXXX)",
        "title": "Verify the authenticity of a Unisciel Certificate",
        "verify": "Verify the score"
      },
      "fill-in-participant-external-id": {
        "announcement": "The organiser needs the information below in order to help you:",
        "buttons": {
          "cancel": "Cancel",
          "continue": "Continue"
        },
        "errors": {
          "max-length-id-pix-label": "Your { idPixLabel } must not exceed 255 characters.",
          "missing-id-pix-label": "Please enter your { idPixLabel }."
        },
        "first-title": "Before starting",
        "label-email": "please fill your college e-mail",
        "title": "Enter my username"
      },
      "join": {
        "button": "Let's go!",
        "fields": {
          "birthdate": {
            "day-error": "Your birth day is in an invalid format.",
            "day-format": "DD",
            "day-label": "Day",
            "label": "Date of birth",
            "month-error": "Your birth month is in an invalid format.",
            "month-format": "MM",
            "month-label": "Month",
            "year-error": "Your birth year is in an invalid format.",
            "year-format": "YYYY",
            "year-label": "Year"
          },
          "firstname": {
            "error": "Please enter your first name.",
            "label": "First name"
          },
          "lastname": {
            "error": "Please enter your last name.",
            "label": "Last name"
          }
        },
        "sco": {
          "associate": "Link",
          "continue-with-pix": "Continue with my Unisciel account",
          "error-not-found": "If you're a pupil '<br>' Check your information (first name, last name and date of birth) or contact a teacher.'<br><br>' If you're a teacher '<br>' Access to this customised test is not available at the moment.",
          "first-title": "{ organizationName }",
          "invalid-reconciliation-error": "An error has occured. <br> Please contact the support.",
          "login-information-message": "The Unisciel account  '<b>'{ connectionMethod }'</b>' will be linked with the pupil:  '<b>'{ firstName }  { lastName }'</b>'.'<b>'If this is you, click on \"Link\". Otherwise, log out.",
          "login-information-title": "Unisciel account information",
          "subtitle": "Please fill in the missing information"
        },
        "sup": {
          "error": "Please check the information you have entered. If you already have a Unisciel account, log in with this account.",
          "fields": {
            "student-number": {
              "error": "You haven't entered your student number.",
              "label": "Student number",
              "modify": "Change student number",
              "not-existing": "I don't have a student number"
            }
          },
          "message": "Enter the information as it appears on your student card to access the test and submit the results. Unisciel will remember it next time.",
          "title": "Register your Unisciel account with {organizationName}."
        },
        "title": "Join"
      },
      "learning-more": {
        "info": "These tutorial links have been suggested by Unisciel users.",
        "title": "To find out more"
      },
      "levelup-notif": {
        "obtained-level": "Level { level } reached!"
      },
      "login-or-register": {
        "invitation": "{ organizationName }",
        "login-form": {
          "button": "Log in",
          "error": "There was an error in the email address or username/password entered.",
          "fields": {
            "login": {
              "label": "Email address or username"
            },
            "password": {
              "label": "Password"
            }
          },
          "forgotten-password": {
            "email": "An email address:",
            "instruction": "Forgotten your password? You have a Unisciel account with:",
            "other-identity": "Username: Contact your teacher to reset it",
            "reset-link": "Click here to reset it."
          },
          "title": "I already have a Unisciel account.",
          "unexpected-user-account-error": "There was an error in the email address or username entered. To continue, log in with your account in this format: "
        },
        "register-form": {
          "button": "Sign up",
          "button-form": "Sign up",
          "error": "An error has occured. Please try again or contact the support.",
          "fields": {
            "birthdate": {
              "day-error": "Your birth day is in an invalid format.",
              "label": "Date of birth (DD/MM/YYYY)",
              "month-error": "Your birth month is in an invalid format.",
              "year-error": "Your birth year is in an invalid format."
            },
            "cgu": "I agree to the '<a href=\"https://ecriplus.fr/conditions-generales/\" class=\"link\" target=\"_blank\" rel=\"noopener noreferrer\">'terms and conditions of use of the Unisciel platform'</a>'",
            "email": {
              "error": "Your email address is invalid.",
              "help": "(eg. name@example.org)",
              "label": "Email address"
            },
            "firstname": {
              "error": "You haven't entered your first name.",
              "label": "First name"
            },
            "lastname": {
              "error": "You haven't entered your last name.",
              "label": "Last name"
            },
            "password": {
              "error": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number.",
              "help": "(At least 8 characters, including one uppercase letter, one lowercase letter and one number)",
              "label": "Password",
              "show-button": "Show password"
            },
            "username": {
              "label": "My username"
            }
          },
          "legal-text": "The information collected in this form is saved in a computer file by Unisciel to enable access to the service offered by Unisciel. They are kept for the duration of use of the service and are intended for Unisciel. Test results may be communicated to third parties, with your consent, if you have been invited to take a specific customised test. In accordance with the French law governing computer technology and freedoms (“Informatique et Libertés”), you can exercise the right to access and rectify your data by emailing our Data Protection Officer at dpo@ecriplus.fr.",
          "not-me": "That's not me",
          "options": {
            "email": "My email address",
            "text": "Sign up with:",
            "username": "My username"
          },
          "title": "Sign up"
        },
        "title": "Log in or register"
      },
      "not-connected": {
        "message": "You've been logged out.'<br>'Thanks! See you soon",
        "title": "Logged out"
      },
      "ordo-proposal": {
        "title": "Answer"
      },
      "password-reset-demand": {
        "actions": {
          "back-home": "Return to homepage",
          "back-sign-in": "Return to log in page",
          "reset": "Reset my password"
        },
        "error": {
          "message": "The email address entered does not match any Unisciel account"
        },
        "fields": {
          "email": {
            "label": "Email address"
          }
        },
        "page-title": "Password forgotten",
        "subtitle": "Enter your email address below",
        "succeed": {
          "help": "If you do not receive this email, please check your spam folder.",
          "instructions": "An email explaining how to reset your password\n has been sent to the email address {email}.",
          "subtitle": "Password reset request"
        },
        "title": "Forgot your password?"
      },
      "profile": {
        "accessibility": {
          "title": "Your Unisciel profile",
          "user-score": "Your Unisciel score",
          "user-skills": "Your Unisciel competences"
        },
        "competence-card": {
          "congrats": "Well done!",
          "details": "details",
          "image-info": {
            "first-level": "Level 1 in progress: {percentageAheadOfNextLevel}% successfully completed.",
            "level": "Current level: {currentLevel}. {percentageAheadOfNextLevel}% remaining to reach next level",
            "no-level": "Competence not started"
          }
        },
        "first-title": "You have 16 competences to test. '<br>'Get your thinking hat on and off we go!",
        "resume-campaign-banner": {
          "accessibility": {
            "resume": "Resume your customised test",
            "share": "Share your customised test results"
          },
          "actions": {
            "continue": "Continue",
            "resume": "Resume"
          },
          "reminder-continue-campaign": "You haven’t finished your customised test",
          "reminder-continue-campaign-with-title": "You haven’t finished the customised test \"{title}\"",
          "reminder-send-campaign": "Don’t forget to submit your results!",
          "reminder-send-campaign-with-title": "customised test \"{title}\" finished. Don’t forget to submit your results!"
        },
        "title": "Competences",
        "total-score-helper": {
          "explanation": "<p>That’s the maximum number of Unisciel points you can get when the 8 levels of the Unisciel framework will be available.'</p><p>'Today, '<span class=\"hexagon-score-information__text--strong\">'the maximum number available is 728 Unisciel points'</span>'.'</p>'",
          "title": "Why 1,024 Unisciel?"
        }
      },
      "profile-already-shared": {
        "actions": {
          "continue": "Continue my Unisciel experience"
        },
        "explanation": "You already submitted the profile below to the organisation {organization}'<br>'on {date} at {hour,time,hhmm}",
        "first-title": "There was a problem",
        "retry": {
          "button": "Resubmit my profile",
          "message": "{organization} encourages you to resubmit your profile to measure your progress."
        },
        "title": "Profile already submitted"
      },
      "proposals": {
        "answer": {
          "qcu": "The answer to the question is: "
        }
      },
      "reset-password": {
        "actions": {
          "sign-in": "Log in",
          "submit": "Send"
        },
        "error": {
          "expired-demand": "We’re sorry, but your request to reset your password has already been used or has expired. Please start again.",
          "forbidden": "An error occurred, please contact the support.",
          "wrong-format": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number."
        },
        "fields": {
          "password": {
            "label": "Password"
          }
        },
        "instruction": "Enter your new password",
        "succeed": "Your password has been changed successfully.",
        "title": "Change my password"
      },
      "result-item": {
        "aband": "No answer",
        "actions": {
          "see-answers-and-tutorials": {
            "label": "Answers and tutorials"
          }
        },
        "ko": "Incorrect answer",
        "ok": "Correct answer",
        "partially": "Incomplete answer",
        "timedout": "Out of time"
      },
      "send-profile": {
        "errors": {
          "archived": "It is no longer possible to submit your profile as the organiser has archived the collection of profiles."
        },
        "first-title": "Submitting your Unisciel profile",
        "form": {
          "continue": "Continue my Unisciel experience",
          "recipient": "Recipient: {recipient}",
          "send": "Submit my profile",
          "shared": "Thank you, your profile has been submitted!"
        },
        "instructions": "You are about to submit the score and levels shown on your Unisciel profile. '<br>' Any changes in your profile after this submission will not be shared. '<br>' Remember to check your profile before submitting it!",
        "title": "Submit my profile"
      },
      "shared-certification": {
        "back-link": "Return to the verification’s page",
        "title": "Share my Unisciel Certificate"
      },
      "sign-in": {
        "actions": {
          "submit": "Log in"
        },
        "error": {
          "message": "There was an error in the email address or username/password entered."
        },
        "fields": {
          "legend": "Information required for sign in.",
          "login": {
            "label": "Email address or username"
          },
          "password": {
            "label": "Password"
          }
        },
        "first-title": "Log in",
        "forgotten-password": "Forgot your password?",
        "or": "OR",
        "pole-emploi": {
          "link": {
            "img": "pôle emploi connect"
          },
          "title": "Sign-in with pôle Emploi"
        },
        "subtitle": {
          "link": "Create an account",
          "text": "Don’t have a Unisciel account yet?"
        },
        "title": "Log in"
      },
      "sign-up": {
        "actions": {
          "submit": "Sign up"
        },
        "fields": {
          "cgu": {
            "accept": "I agree to the ",
            "and": "and ",
            "cgu": "Unisciel terms of use",
            "data-protection-policy": "personal data protection policy",
            "error": "You must agree to the Unisciel terms of use and personal data protection policy to create an account.",
            "label": "Agree Unisciel terms of use and personal data protection policy",
            "pix": ""
          },
          "email": {
            "error": "Your email address is invalid.",
            "help": "(eg. name@example.org)",
            "label": "Email address"
          },
          "firstname": {
            "error": "You have not entered your first name.",
            "label": "First name"
          },
          "lastname": {
            "error": "You have not entered your last name.",
            "label": "Last name"
          },
          "legend": "Information required for sign up.",
          "password": {
            "error": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number.",
            "help": "(at least 8 characters, including one uppercase letter, one lowercase letter and one number)",
            "label": "Password"
          }
        },
        "first-title": "Sign up",
        "instructions": "The fields marked '<abbr title=\"required\" class=\"mandatory-mark\">'*'</abbr>' are required",
        "legal-information": "The information collected in this form is saved in a computer file by Unisciel to enable access to the service offered by Unisciel. It will be stored for a maximum of 5 years from the date of the last access to the user account and archived according to the statutory period of limitation (5 years). It will only be shared with Unisciel and its technical service providers. In accordance with the French law governing computer technology and freedoms (“Informatique et Libertés”), you can exercise the right to access and rectify your data by emailing dpd@ecriplus.fr.",
        "subtitle": {
          "link": "log into your account"
        },
        "title": "Sign up"
      },
      "sitemap": {
        "accessibility": {
          "help": "Help page to answer your questions about Unisciel's accessibility",
          "title": "Accessibility"
        },
        "cgu": {
          "policy": "Personal Data Protection Policy",
          "subcontractors": "List of recipient processors of Unisciel users’ personal data"
        },
        "resources": "Further information",
        "title": "Site map"
      },
      "skill-review": {
        "abstract": "You have mastered '<strong>'{rate, number, ::percent}'</strong><br>' of the competences tested.",
        "abstract-title": "Your result for this customised test",
        "actions": {
          "continue": "Continue my Unisciel experience",
          "improve": "Try again",
          "send": "Submit my results"
        },
        "already-shared": "Thank you, your results have been submitted!",
        "archived": "This customised test has been archived by the organiser.'<br>' It is no longer possible to submit results.",
        "badge-card": {
          "acquired": "Awarded",
          "not-acquired": "Not awarded"
        },
        "badges-title": "Your thematic results",
        "details": {
          "header-result": "Results",
          "header-skill": "Competences tested",
          "percentage": "{rate, number, ::percent}",
          "result": "Overall result",
          "result-by-skill": "Your results for the competence",
          "title": "Your results by competence"
        },
        "improve": {
          "description": "You can retake some of the questions",
          "title": "Want to improve your results?"
        },
        "information": "If you have already completed customised tests on Unisciel, questions you have previously answered have not been asked again. However, the result shown here is based on all of your answers.",
        "net-promoter-score": {
          "link": {
            "href": "https://www.unisciel.fr",
            "label": "Give my feedback"
          },
          "title": "Your opinion counts! Take a few minutes to tell us what you thought of this test and help us improve it."
        },
        "not-finished": "You can’t submit your results yet, we still have a few questions to ask.",
        "organization-message": "Message of your organization",
        "retry": {
          "button": "Retake my customised test",
          "message": "{organizationName} encourages you to retake your test to measure your progress."
        },
        "send-results": "Don't forget to submit your results.",
        "send-status": {
          "in-progress": "Results are being shared..."
        },
        "send-title": "Submit your results",
        "stage": {
          "masteryPercentage": "Success rate: {rate, number, ::percent}",
          "starsAcquired": "{acquired, plural, =0 {no star} other {# stars}} acquired over {total}"
        },
        "title": "Result"
      },
      "terms-of-service": {
        "cgu": "I agree to the '<a href=\"https://ecriplus.fr/conditions-generales/\" class=\"link\" target=\"_blank\">'terms and conditions of use of the Unisciel platform'</a>'",
        "form": {
          "button": "Continue",
          "error-message": "Please agree to the terms and conditions of use."
        },
        "message": "We have updated our terms and conditions of use. Please agree to them in order to continue your experience on Unisciel.",
        "title": "Terms and conditions of use"
      },
      "terms-of-service-pe": {
        "cgu": "I agree to the '<a href=\"https://ecriplus.fr/conditions-generales/\" class=\"link\" target=\"_blank\">'terms and conditions of use of the Unisciel platform'</a>'",
        "form": {
          "button": "Continue",
          "error-message": "Please agree to the terms and conditions of use.",
          "expired-authentication-key": "Your authentication demand has expired. Please click to back button to log in"
        },
        "message": "",
        "title": "Terms and conditions of use"
      },
      "timed-challenge-instructions": {
        "action": "Start",
        "primary": "You have '<span class=\"timed-challenge-instructions__time\">'{time}'</span>' to complete the next question.",
        "secondary": "You can continue answering after this, but the question will not be marked as correct."
      },
      "tutorial": {
        "next": "Next",
        "pages": {
          "page0": {
            "explanation": "If you do not know the answer to a question, \nyou can probably find it on the internet.",
            "icon": "icn-recherche.svg",
            "title": "You can search online..."
          },
          "page1": {
            "explanation": "If you see this picture, only use your own knowledge! \nDon’t search the internet or use your other equipments.\n",
            "icon": "icn-focus.svg",
            "title": "... with the exception of specific questions"
          },
          "page2": {
            "explanation": "Take all the time you need to finish your customised test. \nIf a question is timed, we will let you know.",
            "icon": "icn-temps.svg",
            "title": "There’s no time limit!"
          },
          "page3": {
            "explanation": "Access tutorials to learn more \nabout each question and improve.",
            "icon": "icn-tutos.svg",
            "title": "Tutorials"
          },
          "page4": {
            "explanation": "Based on your answers, \nUnisciel adapts the difficulty of the questions.",
            "icon": "icn-algo.svg",
            "title": "Adapted levels of difficulty"
          }
        },
        "pass": "Ignore",
        "start": "Start my customised test",
        "title": "Campaign tutorial"
      },
      "tutorial-panel": {
        "info": "These tutorial links have been suggested by Unisciel users.",
        "title": "For better results next time"
      },
      "update-expired-password": {
        "button": "Reset",
        "fields": {
          "error": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number.",
          "help": "(At least 8 characters, including one uppercase letter, one lowercase letter and one number)",
          "label": "Password"
        },
        "first-title": "Reset my password",
        "go-to-login": "Log in",
        "subtitle": "Choose a new password to continue",
        "title": "Change my password",
        "validation": "Your password has been updated."
      },
      "user-account": {
        "account-update-email": {
          "email-information": "Please provide a valid email address: it will be used to reset your password if you forget it. '<br>'This will change the email address you use to log in.",
          "fields": {
            "errors": {
              "empty-password": "Your password can't be empty.",
              "invalid-password": "There was an error in the password entered.",
              "mismatching-email": "The email addresses you entered do not match. Please check your entry for spelling errors.",
              "new-email-already-exist": "This email address is already in use.",
              "unknown-error": "An error has occured. Please try again or contact the support.",
              "wrong-email-format": "Your email address is invalid."
            },
            "new-email": {
              "label": "New email address"
            },
            "new-email-confirmation": {
              "label": "Confirm your email address"
            },
            "password": {
              "label": "Password"
            }
          },
          "password-information": "Enter your password to confirm",
          "save-button": "Confirm",
          "title": "Change your email address"
        },
        "account-update-email-with-validation": {
          "fields": {
            "errors": {
              "empty-password": "Your password can't be empty.",
              "invalid-email": "Your email address is invalid.",
              "invalid-password": "There was an error in the password entered.",
              "new-email-already-exist": "This email address is already in use."
            },
            "new-email": {
              "label": "New email address"
            },
            "password": {
              "label": "Password",
              "security-information": "The security of your personal information is essential. We will therefore check that it’s really you who made the request. Please enter your password to get a verification code."
            }
          },
          "save-button": "Get a verification code",
          "title": "Change your email address"
        },
        "connexion-methods": {
          "authentication-methods": {
            "gar": "ENT/Mediacentre",
            "label": "External login",
            "pole-emploi": "Pôle Emploi"
          },
          "edit-button": "Edit",
          "email": "Email address",
          "menu-link-title": "Log in methods",
          "username": "Username"
        },
        "email-verification": {
          "code-explanation-of-use": "To move from one field to another, use either the tabulation, or the left and right arrows of the keyboard. To fill in a field, type a number between 1 and 9, or use the up and down arrows of the keyboard.",
          "code-label": "Field",
          "code-legend": "Input the verification code you received by email",
          "confirmation-message": "Email sent!",
          "description": "Enter the verification code you received at the following email address:",
          "did-not-receive": "You haven’t received anything?",
          "errors": {
            "email-modification-demand-expired": "This code has expired. Please renew your request.",
            "incorrect-code": "The code entered is incorrect.",
            "new-email-already-exist": "This email address is already in use.",
            "unknown-error": "An error has occurred. Please try again or contact the support."
          },
          "send-back-the-code": "Send the code again",
          "title": "Verify your email address",
          "update-successful": "Your email address has been changed!"
        },
        "language": {
          "fields": {
            "select": {
              "labels": {
                "english": "English",
                "french": "French"
              }
            }
          },
          "lang": "Language",
          "menu-link-title": "Language"
        },
        "personal-information": {
          "first-name": "First name",
          "last-name": "Last name",
          "menu-link-title": "Personal information"
        },
        "title": "My account"
      },
      "user-tests": {
        "title": "My customised tests"
      },
      "user-tutorials": {
        "description": "Improve with the help of tutorials suggested by the Unisciel users' community.",
        "empty-list-info": {
          "description": {
            "part1": "As you test your skills, tutorials are recommended. Click on the bookmark icon",
            "part2": "to save the ones you want to see again here!"
          },
          "image-link": "images/illustrations/user-tutorials/illustration-en.svg",
          "title": "You haven’t saved anything yet"
        },
        "label": "Tutorials",
        "list": {
          "title": "My list",
          "tutorial": {
            "actions": {
              "evaluate": {
                "extra-information": "Give feedback on this tutorial",
                "label": "Useful tutorial"
              },
              "remove": {
                "extra-information": "Remove from my list of tutorials",
                "label": "Remove"
              },
              "save": {
                "extra-information": "Save in my list of tutorials",
                "label": "Save"
              }
            },
            "source": "By"
          }
        },
        "title": "My tutorials"
      }
    }
  }], ["fr", {
    "api-error-messages": {
      "bad-request-error": "Les données que vous avez soumises ne sont pas au bon format.",
      "internal-server-error": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
      "join-error": {
        "r11": "Vous possédez déjà un compte Unisciel avec l’adresse e-mail'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R11)",
        "r12": "Vous possédez déjà un compte Unisciel utilisé avec l’identifiant sous la forme prénom.nom suivi de 4 chiffres:'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R12)",
        "r13": "Vous possédez déjà un compte Unisciel via l’ENT dans un autre établissement scolaire.'<br><br>'Pour continuer, contactez un enseignant qui pourra vous donner l’accès à ce compte à l’aide de MaPS Orga.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R13)",
        "r31": "Vous possédez déjà un compte Unisciel avec l’adresse e-mail'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R31)",
        "r32": "Vous possédez déjà un compte Unisciel utilisé avec l’identifiant sous la forme prénom.nom suivi de 4 chiffres:'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R32)",
        "r33": "Vous possédez déjà un compte Unisciel via l’ENT. Utilisez-le pour rejoindre le parcours.",
        "r70": "Une erreur est survenue. Déconnectez-vous et recommencez."
      },
      "login-unauthorized-error": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects.",
      "register-error": {
        "s51": "Vous possédez déjà un compte Unisciel avec l’adresse e-mail'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R51)",
        "s52": "Vous possédez déjà un compte Unisciel utilisé avec l’identifiant sous la forme prénom.nom suivi de 4 chiffres:'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R52)",
        "s53": "Vous possédez déjà un compte Unisciel via l’ENT.'<br>'Utilisez-le pour rejoindre le parcours.",
        "s61": "Vous possédez déjà un compte Unisciel avec l’adresse e-mail'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R61)",
        "s62": "Vous possédez déjà un compte Unisciel utilisé avec l’identifiant sous la forme prénom.nom suivi de 4 chiffres:'<br>'{ value }'<br><br>'Pour continuer, connectez-vous à ce compte ou demandez de l’aide à un enseignant.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R62)",
        "s63": "Vous possédez déjà un compte Unisciel via l’ENT dans un autre établissement scolaire.'<br><br>'Pour continuer, contactez un enseignant qui pourra vous donner l’accès à ce compte à l’aide de MaPS Orga.'<br><br>'(Pour l’enseignant : voir le ’Kit de dépannage’ dans MaPS Orga &gt; Documentation - Code R63)"
      }
    },
    "application": {
      "description": "Unisciel est une plateforme en ligne d'évaluation et de certification des compétences en français écrit à l'université (élèves, étudiant•e•s, décrocheur•se•s)"
    },
    "common": {
      "actions": {
        "back": "Retour",
        "cancel": "Annuler",
        "close": "Fermer",
        "quit": "Quitter",
        "sign-out": "Se déconnecter"
      },
      "anr-credits": "",
      "ecriplus": "Unisciel",
      "ecriplus-logo": "logo Unisciel",
      "form": {
        "error": "erreur",
        "invisible-password": "cacher le mot de passe",
        "mandatory": "obligatoire",
        "mandatory-all-fields": "Tous les champs sont obligatoires.",
        "mandatory-fields": "Les champs marqués de '<abbr title=\"obligatoire\" class=\"mandatory-mark\">'*'</abbr>' sont obligatoires",
        "success": "correct",
        "visible-password": "afficher le mot de passe"
      },
      "french-republic": "République française",
      "level": "niveau",
      "loading": {
        "default": "Chargement en cours",
        "results": "Préparation de vos résultats",
        "test": "Votre test est en cours de chargement"
      },
      "marianne-logo": "Logo de la Marianne",
      "or": "ou",
      "pix": "Unisciel",
      "score": "points",
      "skip-links": {
        "skip-to-content": "Aller au contenu",
        "skip-to-footer": "Aller au bas de page"
      }
    },
    "current-lang": "fr",
    "navigation": {
      "anr-logo": "",
      "back-to-homepage": "Retour à l'accueil",
      "back-to-profile": "Revenir au profil",
      "copyrights": "©",
      "error": "Erreur",
      "footer": {
        "a11y": "Accessibilité",
        "data-protection-policy": "Politique de protection des données",
        "eula": "CGU",
        "help-center": "Centre d'aide",
        "legal-notices": "Mentions légales",
        "sitemap": "Plan du site"
      },
      "homepage": "Page d'accueil Unisciel",
      "main": {
        "code": "J'ai un code",
        "dashboard": "Accueil",
        "discover": "Découvrir MaPS",
        "discover-faq": "F.A.Q",
        "help": "Aide, F.A.Q",
        "link-help": "https://cloud.unisciel.fr/index.php/s/SbWcKr2YxKK9L9f",
        "skills": "Les compétences",
        "start-certification": "Certification (À venir)",
        "tutorials": "Mes tutos"
      },
      "not-logged": {
        "sign-in": "Se connecter",
        "sign-up": "S'inscrire"
      },
      "pix": "Unisciel",
      "user": {
        "account": "Mon compte",
        "certifications": "Mes certifications",
        "sign-out": "Se déconnecter",
        "tests": "Mes parcours"
      },
      "user-logged-menu": {
        "details": "Consulter mes informations"
      }
    },
    "pages": {
      "account-recovery": {
        "errors": {
          "account-exists": "Un compte existe déjà avec cette adresse e-mail.",
          "key-expired": "Le lien de récupération est expiré, ",
          "key-expired-renew-demand-link": "renouvelez votre demande ici.",
          "key-invalid": "Le lien de récupération n’existe pas.",
          "key-used": "Ce compte a déjà été récupéré.",
          "title": "Oups, une erreur est survenue !"
        },
        "find-sco-record": {
          "backup-email-confirmation": {
            "ask-for-new-email-message": "sinon fournissez en une nouvelle",
            "email-already-exist-for-account-message": "L’adresse e-mail ci-dessous est déjà associée à votre compte :",
            "email-is-needed-message": "{ firstName }, nous avons besoin de votre adresse e-mail",
            "email-is-valid-message": "Si elle est valide,",
            "email-reset-message": "réinitialisez votre mot de passe",
            "email-sent-to-choose-password-message": "Nous vous enverrons un e-mail afin de choisir un mot de passe.",
            "form": {
              "actions": {
                "cancel": "Annuler",
                "submit": "C'est parti"
              },
              "email": "Votre adresse e-mail",
              "error": {
                "email-already-exist": {
                  "existing-email": "Vous possédez déjà cette adresse e-mail. Si elle est valide,",
                  "init-password": " réinitialisez votre mot de passe",
                  "new-email": ", sinon saisissez une nouvelle."
                },
                "empty-email": "Le champ adresse e-mail est obligatoire.",
                "new-backup-email": "Veuillez saisir un e-mail valide pour récupérer votre compte",
                "new-email-already-exist": "Cette adresse e-mail est déjà utilisée",
                "wrong-email-format": "Votre adresse e-mail n’est pas valide."
              }
            }
          },
          "confirmation-step": {
            "buttons": {
              "cancel": "Annuler",
              "confirm": "Je confirme"
            },
            "certify-account": "J’atteste sur l’honneur que le compte associé à ces données m’appartient.",
            "contact-support": "Si vous constatez une erreur ou si ces données ne sont pas les vôtres, ",
            "fields": {
              "first-name": "Votre prénom",
              "last-name": "Votre nom",
              "latest-organization-name": "Votre dernier établissement",
              "username": "Votre identifiant"
            },
            "found-account": "Nous avons trouvé votre compte :",
            "good-news": "Bonne nouvelle { firstName } !"
          },
          "conflict": {
            "found-you-but": "{ firstName }, on vous a retrouvé mais ...",
            "title": "Conflit",
            "warning": "Par précaution nous devons vérifier vos données ensemble."
          },
          "contact-support": {
            "link-text": "contactez le support.",
            "link-url": "https://unisciel.fr"
          },
          "send-email-confirmation": {
            "check-spam": "Si vous ne voyez pas cet e-mail, vérifiez vos courriers indésirables.",
            "return": "Retour",
            "send-email": "Nous venons de vous adresser un mail qui vous permettra de choisir un mot de passe. Vous pouvez consulter dès maintenant votre messagerie.",
            "title": "Récupération de votre compte Unisciel"
          },
          "student-information": {
            "errors": {
              "empty-first-name": "Le champ prénom est obligatoire.",
              "empty-ine-ina": "Le champ INE est obligatoire.",
              "empty-last-name": "Le champ nom est obligatoire.",
              "invalid-ine-ina-format": "Le champ INE n'est pas au bon format.",
              "not-found": "Unisciel ne reconnait pas vos données. Vérifiez qu'elles sont exactes, sinon "
            },
            "form": {
              "birthdate": "Date de naissance",
              "first-name": "Prénom",
              "ine-ina": "INE (Identifiant National Élève)",
              "label": {
                "birth-day": "jour de naissance",
                "birth-month": "mois de naissance",
                "birth-year": "année de naissance"
              },
              "last-name": "Nom",
              "placeholder": {
                "birth-day": "JJ",
                "birth-month": "MM",
                "birth-year": "AAAA"
              },
              "submit": "Retrouvez-moi !",
              "tooltip": "<strong>Où trouver mon INE ?</strong> <p>Cet identifiant se trouve le plus souvent sur le bulletin scolaire, sinon votre ancien établissement peut vous le fournir.</p>"
            },
            "mandatory-all-fields": "Tous les champs sont obligatoires.",
            "subtitle": {
              "link": "réinitialisez votre mot de passe ici.",
              "text": "Si vous possédez un compte avec une adresse e-mail valide, "
            },
            "title": "Vous avez quitté le système scolaire et vous souhaitez récupérer votre accès à Unisciel"
          },
          "title": "Récupérer mon compte"
        },
        "support": {
          "recover": " pour obtenir une assistance.",
          "url": "https://unisciel.fr",
          "url-text": "Contactez le support"
        },
        "update-sco-record": {
          "fill-password": "Saisissez un mot de passe et Unisciel est à vous !",
          "form": {
            "email-label": "Adresse e-mail",
            "errors": {
              "empty-password": "Le champ mot de passe est obligatoire.",
              "invalid-password": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre."
            },
            "login-button": "Je me connecte",
            "password-label": "Mot de passe"
          },
          "welcome-message": "Bon retour parmi nous { firstName } !"
        }
      },
      "assessment-banner": {
        "title": "Épreuve pour l'évaluation : "
      },
      "assessment-results": {
        "actions": {
          "continue-pix-experience": "Continuer mon expérience Unisciel",
          "return-to-homepage": "Revenir à l'accueil"
        },
        "answers": {
          "header": "Vos réponses"
        },
        "title": "Fin du test"
      },
      "campaign": {
        "errors": {
          "no-longer-accessible": "Oups, la page demandée n’est plus accessible.",
          "not-accessible": "Oups, la page demandée n’est pas accessible."
        }
      },
      "campaign-landing": {
        "assessment": {
          "action": "Je commence",
          "announcement": "Démarrez votre parcours d'évaluation personnalisé.'<br>' Inscrivez-vous ou connectez-vous sur la plateforme Unisciel et lancez votre test.",
          "certify": {
            "description": "La certification Unisciel permet de valoriser ses compétences en français écrit auprès des employeurs.",
            "title": "Préparer une certification qui vise à être reconnue par l’État et le monde professionnel"
          },
          "details": "Durant ce parcours, vous aurez l’opportunité de :",
          "develop": {
            "description": "Après 5 épreuves, vous pouvez visualiser vos réponses et suivre des explications pour développer vos compétences.",
            "title": "Découvrir vos résultats au fil du test et consulter des indices pour progresser."
          },
          "evaluate": {
            "complement": "Le service public d’évaluation et de certification des compétences en français. Unisciel permet d’évaluer son niveau dans 4 grands domaines de compétences.",
            "list": "'<ul><li>'domaine du mot,'</li><li>'domaine de la phrase,'</li><li>'domaine du texte,'</li><li>'domaine du discours'</li></ul>'",
            "more-info": {
              "label": "En savoir plus.",
              "link": "https://unisciel.fr/descriptif-de-competences"
            },
            "title": "Mesurer vos compétences en français avec des tests ludiques sur différents thèmes comme :"
          },
          "legal": "En cliquant sur “Je commence”, les informations relatives à votre avancée dans le parcours seront transmises à l’organisateur du parcours pour lui permettre de vous accompagner. A la fin du parcours, en cliquant sur le bouton “J’envoie mes résultats” vos résultats seront transmis à l’organisateur.",
          "title": "Commencez votre parcours"
        },
        "profiles-collection": {
          "action": "C'est parti !",
          "announcement": "Envoyez votre profil à votre enseignant ou établissement pour partager les niveaux atteints dans chaque compétence testée. Votre profil ne sera accessible qu'après l'envoi, garantissant ainsi la confidentialité de vos données, transmises uniquement avec votre consentement.",
          "legal": "Les informations relatives à votre profil Unisciel seront transmises à l'organisateur pour lui permettre de vous accompagner. Elle ne seront transmises qu'avec votre consentement.",
          "step-one": {
            "evaluation-instruction-title": "Consigne d’évaluation",
            "evaluation-instruction-title-mobile": "Consigne",
            "privacy-policy": "politique de confidentialité",
            "service-end": " d’Unisciel",
            "service-middle": " et la ",
            "service-start": "Vous pouvez consulter, ",
            "use-condition": "condition d’utilisation"
          },
          "stepper": {
            "step-one": "1- Je démarre",
            "step-three": "3- J’envoie",
            "step-two": "2- Je m’identifie <br/><span class=\"sub-midle\">(Optionnel)</span>"
          },
          "title": "J'envoie mon profil en <span>étape 1</span>"
        },
        "title": "Présentation"
      },
      "campaign-participation": {
        "title": "Parcours"
      },
      "campaign-participation-overview": {
        "card": {
          "finished-at": "Terminé le {date}",
          "results": "{result, number, ::percent} de réussite",
          "resume": "Reprendre",
          "see-more": "Voir le détail",
          "send": "Envoyer mes résultats",
          "stages": "{count} { count, plural, =0 {étoile} =1 {étoile} other {étoiles} } sur {total}",
          "started-at": "Commencé le {date}",
          "tag": {
            "archived": "Archivé",
            "completed": "À envoyer",
            "finished": "Terminé",
            "started": "En cours"
          },
          "text-archived": "Parcours archivé par votre organisation.'<br>'Vos résultats n'ont pas pu être envoyés."
        },
        "title": "Parcours"
      },
      "certificate": {
        "attestation": "Télécharger mon attestation",
        "back-link": "Retour à mes certifications",
        "candidate-birth": "Né(e) le {birthdate}",
        "candidate-birth-complete": "Né(e) le {birthdate} à {birthplace}",
        "certification-center": "Centre de certification :",
        "clea": {
          "alternative": "Certification cléA numérique"
        },
        "competences": {
          "information": "Votre score certifié a été calculé en fonction de vos réponses lors du passage de la certification. Il peut donc différer du nombre de points Unisciel affiché dans votre profil. Le jour de votre certification il était possible d’atteindre le niveau {maxReachableLevelOnCertificationDate} des compétences et {maxReachablePixCountOnCertificationDate} points au maximum.",
          "subtitle": "(niveaux sur {maxReachableLevel})",
          "title": "Compétences certifiées"
        },
        "complementary": {
          "alternative": "Certification complémentaire",
          "title": "Autre(s) certification(s) obtenue(s)"
        },
        "exam-date": "Date de passage :",
        "hexagon-score": {
          "certified": "certifiés"
        },
        "issued-on": "Délivré le",
        "jury-info": "Les observations du jury ne sont pas partagées sur la page de vérification de votre certificat.",
        "jury-title": "Observations du jury",
        "title": "Certificat Unisciel",
        "validity": "Certificat valable à vie",
        "verification-code": {
          "alt": "Copier",
          "copied": "Code copié !",
          "copy": "Copier le code",
          "info": "(?)",
          "title": "Code de vérification",
          "tooltip": "Communiquez ce code pour permettre à un tiers de vérifier l'authenticité de votre certificat"
        }
      },
      "certification-joiner": {
        "congratulation-banner": {
          "clea-eligibility": "Vous êtes également éligible à la certification CléA numérique.",
          "link": {
            "text": "Comment se certifier ?",
            "url": "https://unisciel.fr"
          },
          "message": "Bravo {fullName},'<br>'votre profil est certifiable.",
          "pix-plus-droit-expert-eligibility": "Vous êtes également éligible à la certification Unisciel Droit Expert.",
          "pix-plus-droit-maitre-eligibility": "Vous êtes également éligible à la certification Unisciel Droit Maître."
        },
        "error-messages": {
          "generic": "Oups ! Nous ne parvenons pas à vous trouver.\nVérifiez vos informations afin de continuer ou prévenez le surveillant.",
          "session-not-accessible": "La session que vous tentez de rejoindre n'est plus accessible.",
          "wrong-account": "Oups ! Il semble que vous n’utilisiez pas le bon compte Unisciel pour rejoindre cette session de certification.\nPour continuer, connectez-vous au bon compte Unisciel ou demandez de l’aide au surveillant."
        },
        "first-title": "Rejoindre une session",
        "form": {
          "actions": {
            "submit": "Continuer"
          },
          "fields": {
            "birth-date": "Date de naissance",
            "birth-day": "jour de naissance (JJ)",
            "birth-month": "mois de naissance (MM)",
            "birth-name": "Nom de naissance",
            "birth-year": "année de naissance (AAAA)",
            "first-name": "Prénom",
            "session-number": "Numéro de session"
          },
          "fields-validation": {
            "session-number-error": "Le numéro de session est composé uniquement de chiffres."
          }
        },
        "title": "Rejoindre une session de certification"
      },
      "certification-not-certifiable": {
        "action": {
          "back": "Retour à l'accueil"
        },
        "text": "Pour faire certifier votre profil, vous devez avoir obtenu un niveau supérieur à 0 dans 5 compétences minimum.",
        "title": "Votre profil n'est pas encore certifiable."
      },
      "certification-results": {
        "action": {
          "confirm": "Je confirme",
          "logout": "Se déconnecter"
        },
        "finished": {
          "description": "Vos résultats seront prochainement disponibles depuis votre compte.",
          "title": "Bravo, c'est fini !",
          "warning-text": "Si cet ordinateur n’est pas le vôtre, pensez à vous déconnecter."
        },
        "flag-alt": "Drapeau",
        "not-finished": {
          "supervisor": "Appelez le surveillant : '<br>'Il doit impérativement voir cet écran pour finaliser votre certification.",
          "title": "Vous avez presque terminé",
          "warning-text": "Le surveillant a bien vu cet écran."
        },
        "title": "Avancement de votre certification"
      },
      "certification-start": {
        "access-code": "Saisissez le code d'accès communiqué par le surveillant",
        "actions": {
          "submit": "Commencer mon test"
        },
        "cgu": {
          "contact": {
            "email": "dpo@unisciel.fr",
            "info": "Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en envoyant un mail à "
          },
          "info": "En cliquant sur \"Commencer mon test\", j’accepte que mes données d’identité, le numéro de certification et les circonstances de la passation telles que renseignées par le surveillant soient communiquées à Unisciel. Unisciel les utilisera lors de la délibération du jury pour produire et archiver mes résultats et pour éditer mon certificat. Si cette certification m’a été prescrite par une organisation, j’accepte qu'Unisciel lui communique mes résultats."
        },
        "error-messages": {
          "access-code-error": "Ce code n’existe pas ou n’est plus valide.",
          "generic": "Une erreur serveur inattendue vient de se produire.",
          "session-not-accessible": "La session de certification n'est plus accessible."
        },
        "first-title": "Vous allez commencer votre test de certification",
        "title": "Rejoindre une session de certification"
      },
      "certifications-list": {
        "header": {
          "certification-center": "centre de certification",
          "date": "date",
          "score": "points Unisciel",
          "status": "statut"
        },
        "no-certification": {
          "text": "Vous n'avez pas encore de certification."
        },
        "statuses": {
          "cancelled": {
            "title": "Certification annulée"
          },
          "fail": {
            "action": "détail",
            "title": "Certification non obtenue"
          },
          "not-published": {
            "title": "En attente du résultat"
          },
          "success": {
            "action": "voir les résultats",
            "title": "Certification obtenue"
          }
        },
        "title": "Mes Certifications"
      },
      "challenge": {
        "actions": {
          "continue": "Poursuivre",
          "go-to-next": " et je vais à la prochaine question",
          "skip": "Je passe",
          "validate": "Je valide"
        },
        "already-answered": "Vous avez déjà répondu à cette question",
        "answer-input": {
          "numbered-label": "Réponse {number}"
        },
        "certification": {
          "banner": {
            "certification-number": "N° de certification"
          },
          "feedback-panel": {
            "certification-number": "votre n° de certification (en haut à droite de l'écran)",
            "description": "Pour signaler un problème, appelez votre surveillant et communiquez-lui les informations suivantes :",
            "problem": "le problème rencontré",
            "question-number": "le numéro de la question (en haut à droite de l'écran)"
          },
          "title": "Certification {certificationNumber}"
        },
        "embed-simulator": {
          "actions": {
            "launch": "Je lance l’application",
            "reset": "Réinitialiser"
          },
          "placeholder": "Chargement de l'application en cours"
        },
        "feedback-panel": {
          "actions": {
            "open-close": "Signaler un problème"
          },
          "description": "Unisciel est à l’écoute de vos remarques pour améliorer les épreuves proposées !*",
          "email-body": "%0D%0A%0D%0A%0D%0ANe pas effacer:%0D%0ARéférence= ",
          "form": {
            "actions": {
              "submit": "Envoyer"
            },
            "fields": {
              "category-selection": {
                "label": "J'ai un problème avec",
                "options": {
                  "accessibility": "L’accessibilité de l’épreuve",
                  "answer": "La réponse",
                  "download": "Le fichier à télécharger",
                  "embed": "Le simulateur / l'application",
                  "link": "Le lien indiqué dans la question",
                  "other": "Autre",
                  "picture": "L'image",
                  "question": "La question",
                  "tutorial": "Le tutoriel"
                }
              },
              "detail-selection": {
                "aria-first": "Vous avez un problème avec",
                "aria-secondary": "Précisez votre problème",
                "label": "Précisez",
                "options": {
                  "answer-not-accepted": "Ma réponse est correcte mais n’a pas été validée",
                  "answer-not-agreed": "Je ne suis pas d’accord avec la réponse",
                  "download": {
                    "edit-failure": {
                      "label": "Je n’arrive pas à modifier le fichier",
                      "solution": "Le fichier est probablement ouvert en “Lecture seule” ou “Mode protégé”. '<br>'Cliquez sur “Activer la modification” ou “Éditer le document” sur le bandeau en haut du fichier s’il s’affiche. '<br>'Sinon enregistrez le fichier sous un autre nom et ouvrez ce nouveau fichier."
                    },
                    "lost": {
                      "label": "Je ne retrouve pas le fichier téléchargé",
                      "solution": "Par défaut, un fichier téléchargé est enregistré dans un dossier “Téléchargements” ou “Downloads”. Il se peut aussi qu’il soit téléchargé au même endroit que votre dernier téléchargement..."
                    },
                    "open-failure": {
                      "label": "Je n’arrive pas à ouvrir le fichier sur un ordinateur",
                      "solution": "Pour réussir cette épreuve, vous pouvez utiliser la suite LibreOffice, gratuite et disponible pour PC et Mac. Elle contient Libre Office Writer (équivalent à Word) et Libre Office Calc (équivalent à Excel).'<a href=\"https://fr.libreoffice.org/download/telecharger-libreoffice\">'Télécharger Libre Office'</a>'"
                    },
                    "other": "J’ai un autre problème avec le fichier"
                  },
                  "embed-displayed-on-desktop-with-problems": {
                    "label": "Sur ordinateur, le simulateur s'affiche mais a un problème",
                    "solution": "Nous faisons notre possible pour que les simulateurs fonctionnent sur tous les appareils, tous les OS et tous les navigateurs mais il se peut que vous utilisiez un système particulier. Vous pouvez arrêter là et reprendre sur un ordinateur.'<br>'Précisez votre problème en indiquant votre navigateur et votre système d'exploitation."
                  },
                  "embed-displayed-on-mobile-devices-with-problems": {
                    "label": "Sur téléphone ou tablette, le simulateur s'affiche mais a un problème",
                    "solution": "Nous faisons notre possible pour que les simulateurs fonctionnent sur tous les appareils, tous les OS et tous les navigateurs mais il se peut que vous utilisiez un système particulier.'<br>'Précisez votre problème en indiquant votre type d'appareil (smartphone, tablette...), votre système d'exploitation et votre navigateur."
                  },
                  "embed-not-displayed": {
                    "label": "Le simulateur / l’application ne s’affiche pas",
                    "solution": "Votre connexion internet est peut-être trop faible.'<br>'Rechargez la page en appuyant sur le bouton actualiser '<img class=\"tuto-icon\" src=\"/images/icons/icon-reload.svg\" alt=\"\" />' à côté de la barre d’adresse. Attendez un peu, le simulateur va peut-être s’afficher. '<br><br>'Si ce n’est pas le cas, pouvez-vous retenter à un autre moment ou depuis un autre endroit avec une meilleure connexion ?"
                  },
                  "embed-other": "Le simulateur / l’application a un autre problème",
                  "link-other": "Le lien ne fonctionne pas ou a un autre problème",
                  "link-unauthorized": {
                    "label": "Le lien est bloqué par mon organisation / établissement...",
                    "solution": "Vous appartenez peut-être à une organisation (établissements scolaires, universitaires, entreprises, administrations, association...) qui protège ses utilisateurs et ses équipements en limitant les pages Internet auxquelles vous avez accès.'<br>'"
                  },
                  "other-challenge-proposal": "J’ai une idée (géniale) d’épreuve à proposer",
                  "other-difficulty": "J’ai un autre problème",
                  "other-site-improvement": "J’ai une suggestion d’amélioration de la plateforme",
                  "picture-not-displayed": {
                    "label": "L'image ne s'affiche pas",
                    "solution": "Votre connexion internet est peut-être trop faible.'<br>'Rechargez la page en appuyant sur le bouton actualiser '<img class=\"tuto-icon\" src=\"/images/icons/icon-reload.svg\" alt=\"\" />' à côté de la barre d’adresse. Attendez un peu, l'image va peut-être s’afficher. '<br><br>'Si ce n’est pas le cas, pouvez-vous retenter à un autre moment ou depuis un autre endroit avec une meilleure connexion ?"
                  },
                  "picture-other": "L'image a un autre problème",
                  "question-improvement": "Je souhaite proposer une amélioration de la question",
                  "question-not-understood": "Je ne comprends pas la question",
                  "tutorial-link-error": "Le lien vers le tutoriel mène vers une autre page ou une page d’erreur",
                  "tutorial-not-accepted": "Le tutoriel n’est pas adapté",
                  "tutorial-proposal": "J’ai un tutoriel à vous proposer"
                },
                "problem-suggestion-description": "Décrivez votre problème ou votre suggestion"
              }
            },
            "status": {
              "error": {
                "empty-message": "Vous devez saisir un message.",
                "max-characters": "Votre message est limité à 10 000 caractères."
              },
              "success": "'<p>'Votre commentaire a bien été transmis à l’équipe du projet Unisciel.'</p><p>'Merci !'</p>'"
            }
          },
          "information": {
            "data-usage": "'<p>'Unisciel traite les données de cette zone pour gérer et analyser la difficulté rencontrée et bénéficier de votre retour d'expérience. Vous disposez de droits sur vos données qui peuvent être exercés auprès de '<a href=\"mailto:dpo@unisciel.fr\" class=\"link\">'dpo@unisciel.fr'</a>'.'</p><p><a href=\"https://unisciel.fr\" target=\"_blank\" class=\"link\">'Pour en savoir plus sur la protection de vos données et sur vos droits.'</a></p>'",
            "guidance": "'<p>'* Soyez attentifs à ce que vous écrivez dans cette zone : restez objectif et factuel pour votre intérêt et celui des autres.'</p><p>'Notamment, ne saisissez aucune information, vous concernant ou concernant des tiers, sur la santé, la religion, les opinions politiques, syndicales et philosophiques, les origines ethniques, ainsi que sur les sanctions et condamnations.'</p>'"
          },
          "support-email": "support@unisciel.fr"
        },
        "has-focused-out-of-window": {
          "certification": "Nous avons détecté un changement de page.'<br>'Votre réponse sera comptée comme fausse.",
          "default": "Nous avons détecté un changement de page.'<br>'En certification, votre réponse ne serait pas validée."
        },
        "illustration": {
          "placeholder": "Chargement de l'image en cours"
        },
        "is-focused-challenge": {
          "info-alert": {
            "subtitle": "En certification, si vous changez de page, votre réponse ne sera pas validée.",
            "title": "Répondez à cette question sans chercher sur internet ni utiliser de logiciels."
          }
        },
        "parts": {
          "answer-input": "Votre réponse",
          "feedback": "Signaler un problème",
          "instruction": "Consigne de l'épreuve",
          "progress": "Votre progression",
          "validation": "Valider ou passer votre épreuve"
        },
        "progress-bar": {
          "label": "Question"
        },
        "skip-error-message": {
          "qcm": "Pour valider, sélectionnez au moins une réponse. Sinon, passez.",
          "qcu": "Pour valider, sélectionnez une réponse. Sinon, passez.",
          "qroc": "Pour valider, veuillez remplir le champ texte. Sinon, passez.",
          "qroc-auto-reply": "“Vous pouvez valider” s‘affiche quand l‘épreuve est réussie. Essayez encore ou passez.",
          "qroc-number": "Pour valider, veuillez saisir un nombre sinon passez.",
          "qrocm": "Pour valider, veuillez remplir tous les champs réponse. Sinon, passez."
        },
        "statement": {
          "alternative-instruction": {
            "actions": {
              "display": "Afficher l'alternative textuelle",
              "hide": "Cacher l'alternative textuelle"
            }
          },
          "external-link-title": "Nouvelle fenêtre",
          "file-download": {
            "actions": {
              "choose-type": "Choisissez le type de fichier que vous voulez utiliser",
              "download": "Télécharger"
            },
            "description": "Unisciel vous laisse le choix du format de fichier à télécharger. Si vous ne savez pas quelle option retenir, conservez le choix par défaut. Il correspond au format de fichier pris en charge par le plus grand nombre de logiciels.",
            "file-type": "fichier .{fileExtension}",
            "help": "Besoin d'aide pour '<a href=\"https://unisciel.fr\" class=\"challenge-statement__action-help--link\" target=\"_blank\">'ouvrir, modifier ou retrouver ce fichier'</a>' ?"
          },
          "tooltip": {
            "aria-label": "Afficher l'indication sur l'épreuve.",
            "close": "J'ai compris",
            "focused": {
              "content": "<p class=\"tooltip-tag-information__title--focused\">Restez sur cette page pour répondre !</p> N'utilisez pas internet ou une application pour répondre. Toute activité en dehors de la zone est détectée.",
              "title": "Mode Focus"
            },
            "other": {
              "content": "Vous êtes libre d’utiliser internet ou des applications pour répondre à cette question.",
              "title": "Mode Libre"
            }
          }
        },
        "timed": {
          "cannot-answer": "Le temps imparti est écoulé. Votre réponse ne sera pas validée."
        },
        "title": {
          "default": "Mode libre - Question {stepNumber} sur {totalChallengeNumber}",
          "focused": "Mode focus - Question {stepNumber} sur {totalChallengeNumber}",
          "focused-out": "Échoué - Mode focus - Question {stepNumber} sur {totalChallengeNumber}",
          "timed-out": "Temps écoulé - Question {stepNumber} sur {totalChallengeNumber}"
        }
      },
      "checkpoint": {
        "actions": {
          "next-page": {
            "continue": "Continuer",
            "results": "Voir mes résultats"
          }
        },
        "answers": {
          "already-finished": {
            "explanation": "Vous avez déjà répondu aux questions, lors de vos parcours précédents. Vous pouvez directement accéder à vos résultats.",
            "info": "C’est déjà fini, ne soyez pas surpris !"
          },
          "header": "vos réponses"
        },
        "completion-percentage": {
          "caption": "avancement",
          "label": "'<p class=\"sr-only\">'Vous avez effectué '</p>'{completionPercentage}%'<p class=\"sr-only\">' de votre parcours.'</p>'"
        },
        "title": {
          "assessment-progress": "Avancement",
          "end-of-assessment": "Fin de votre évaluation"
        }
      },
      "comparison-window": {
        "results": {
          "a11y": {
            "given-answer": "réponse donnée",
            "the-answer-was": "La bonne réponse était"
          },
          "aband": {
            "title": "Vous n’avez pas donné de réponse",
            "tooltip": "Sans réponse"
          },
          "abandAutoReply": {
            "title": "Vous avez passé l’épreuve",
            "tooltip": "Épreuve passée"
          },
          "default": {
            "title": "",
            "tooltip": "Correction automatique en cours de développement ;)"
          },
          "feedback": {
            "correct": "Réponse correcte",
            "wrong": "Réponse incorrecte. '<br>'La bonne réponse est la réponse : "
          },
          "focusedOut": {
            "title": "Vous êtes sorti de l'épreuve",
            "tooltip": "Épreuve échouée"
          },
          "ko": {
            "title": "Vous n’avez pas la bonne réponse",
            "tooltip": "Réponse incorrecte"
          },
          "koAutoReply": {
            "title": "Vous n’avez pas réussi l’épreuve",
            "tooltip": "Épreuve non réussie"
          },
          "ok": {
            "title": "Vous avez la bonne réponse !",
            "tooltip": "Réponse correcte"
          },
          "okAutoReply": {
            "title": "Vous avez réussi l’épreuve",
            "tooltip": "Épreuve réussie"
          },
          "partially": {
            "title": "Vous avez donné une réponse partielle",
            "tooltip": "Réponse partielle"
          },
          "timedout": {
            "title": "Vous avez dépassé le temps imparti",
            "tooltip": "Temps dépassé"
          },
          "tips": {
            "other-proposition": "Autre proposition",
            "your-answer": "Votre réponse"
          }
        },
        "upcoming-tutorials": "Bientôt ici des tutoriels pour vous aider à réussir ce type d'épreuves !!"
      },
      "competence-details": {
        "actions": {
          "continue": {
            "label": "Reprendre"
          },
          "improve": {
            "description": {
              "countdown": "{ daysBeforeImproving, plural, =0 {0 jour.} =1 {1 jour.} other {# jours.} }",
              "waiting-text": "Tenter le niveau supérieur dans"
            },
            "improvingText": "Tentez d'obtenir le niveau supérieur !",
            "label": "Retenter"
          },
          "reset": {
            "description": "Remise à zéro disponible dans { daysBeforeReset, plural, =0 {0 jour.} =1 {1 jour.} other {# jours.} }",
            "label": "Remettre à zéro",
            "modal": {
              "important-message": "Vos { earnedPix } points Unisciel vont être supprimés.",
              "important-message-above-level-one": "Votre niveau { level } et vos { earnedPix } points Unisciel vont être supprimés.",
              "title": "Remise à zéro de la compétence",
              "warning": {
                "certification": "si vous souhaitez faire certifier votre profil et vos différents résultats, cela pourrait altérer votre certification.",
                "header": "Attention : ",
                "ongoing-assessment": "si vous avez un parcours d’évaluation en cours, certaines questions pourront vous être reposées."
              }
            }
          },
          "start": {
            "label": "Commencer"
          }
        },
        "for-competence": "la compétence {competence}",
        "next-level-info": "{ remainingPixToNextLevel } points Unisciel avant le niveau { level }",
        "title": "Compétence",
        "tutorials": {
          "description": "Voici une sélection de tutos qui pourront vous aider à gagner des points Unisciel.",
          "title": "Cultivez vos compétences"
        }
      },
      "competence-result": {
        "header": {
          "congratulations": "Félicitations !",
          "not-bad": "Pas mal, mais ce n'est pas encore ça !",
          "not-bad-subtitle": "Encore quelques petits efforts et vous décrocherez le premier niveau.",
          "too-bad": "Tant pis !",
          "too-bad-subtitle": "Manifestement, ce n’est pas votre jour mais vous ferez mieux la prochaine fois.",
          "you-have-earned": "Vous avez",
          "you-have-reached-level": "Vous avez atteint"
        },
        "title": "Résultats de votre compétence"
      },
      "dashboard": {
        "campaigns": {
          "resume": {
            "action": "Continuer",
            "text": "<h2>N'oubliez pas de finaliser votre envoi de profil !</h2>"
          },
          "subtitle": "Continuez vos parcours de test en cours ou envoyez vos résultats",
          "tests-link": "Tous mes parcours",
          "title": "Parcours"
        },
        "empty-dashboard": {
          "link-to-competences": "Voir mes compétences",
          "message": "<h2>Bravo vous avez terminé les compétences recommandées !</h2> <p> Pour aller plus loin continuez à vous entraîner en retentant des compétences. </p>"
        },
        "improvable-competences": {
          "profile-link": "Toutes les compétences",
          "subtitle": "Prêt à faire passer votre compétence au niveau supérieur ?",
          "title": "Retenter une compétence"
        },
        "presentation": {
          "link": {
            "text": "En savoir plus",
            "url": "https://unisciel.fr"
          },
          "message": "<h2>Bonjour { firstname }, découvrez votre tableau de bord.</h2><p>Accédez rapidement et facilement à vos parcours et vos tests de compétence déjà commencés.<br/>Retrouvez l’évolution de vos points Unisciel et vérifiez si votre profil est prêt pour la certification Unisciel.</p>"
        },
        "recommended-competences": {
          "profile-link": "Toutes les compétences",
          "subtitle": "Explorez les compétences recommandées pour vous.",
          "title": "Compétences recommandées"
        },
        "score": {
          "profile-link": "Voir mes compétences"
        },
        "started-competences": {
          "subtitle": "Continuez vos tests de compétences, retrouvez les plus récents ici.",
          "title": "Reprendre une compétence"
        },
        "title": "Accueil"
      },
      "error": {
        "content-text": "'<p>'Veuillez nous excuser pour ce désagrément'</p>'",
        "first-title": "Oups ! un problème est survenu",
        "send-report": "Envoyer un rapport d'erreur"
      },
      "explication-panel": {
        "title": "Explications"
      },
      "fill-in-campaign-code": {
        "description": "Ce code est composé de 9 chiffres et/ou lettres. Il est transmis par votre enseignant. Le code permet de tester vos compétences et  d’envoyer votre profil  et vos résultats à  votre enseignant",
        "errors": {
          "forbidden": "Oups ! nous ne parvenons pas à vous trouver. Vérifiez vos informations afin de continuer ou prévenez l’organisateur.",
          "missing-code": "Veuillez saisir un code.",
          "not-found": "Votre code est erroné, veuillez vérifier ou contacter l’organisateur."
        },
        "faq": "Qu’est ce qu’un Code parcours et comment l’utiliser ?",
        "first-title-connected": "{ firstName }, saisissez votre code",
        "first-title-not-connected": "Saisissez votre code",
        "start": "Commencer",
        "title": "J'ai un code",
        "warning-message": "Vous n'êtes pas <span>{ firstName } { lastName }</span> ?",
        "warning-message-logout": "Se déconnecter"
      },
      "fill-in-certificate-verification-code": {
        "description": "La certification Unisciel atteste d’un niveau de maîtrise des compétences en français écrit.",
        "errors": {
          "missing-code": "Merci de renseigner le code de vérification.",
          "not-found": "Il n'y a pas de certificat Unisciel correspondant.",
          "unallowed-access": "Merci de renseigner le code de vérification au format P-XXXXXXXX dans le champ ci-dessus.",
          "wrong-format": "Veuillez vérifier le format de votre code (P-XXXXXXX)."
        },
        "first-title": "Vérifier un certificat Unisciel",
        "label": "Code de vérification (P-XXXXXXXX)",
        "title": "Vérifier un certificat Unisciel",
        "verify": "Vérifier le certificat"
      },
      "fill-in-participant-external-id": {
        "announcement": "Pour vous accompagner efficacement, votre enseignant ou établissement a besoin des informations suivantes :",
        "buttons": {
          "cancel": "Annuler",
          "continue": "Continuer"
        },
        "errors": {
          "max-length-id-pix-label": "Votre { idPixLabel } ne doit pas dépasser les 255 caractères.",
          "missing-id-pix-label": "Merci de renseigner votre { idPixLabel }."
        },
        "first-title": "J’envoie mon profil en <span>étape 2</span>",
        "label-email": "Merci d’indiquer votre adresse e-mail universitaire",
        "title": "Saisir mon identifiant"
      },
      "join": {
        "button": "C'est parti !",
        "fields": {
          "birthdate": {
            "day-error": "Votre jour de naissance n’est pas valide.",
            "day-format": "JJ",
            "day-label": "jour de naissance",
            "label": "Date de naissance",
            "month-error": "Votre mois de naissance n’est pas valide.",
            "month-format": "MM",
            "month-label": "mois de naissance",
            "year-error": "Votre année de naissance n’est pas valide.",
            "year-format": "AAAA",
            "year-label": "année de naissance"
          },
          "firstname": {
            "error": "Votre prénom n’est pas renseigné.",
            "label": "Prénom"
          },
          "lastname": {
            "error": "Votre nom n’est pas renseigné.",
            "label": "Nom"
          }
        },
        "sco": {
          "associate": "Associer",
          "continue-with-pix": "Continuer avec mon compte Unisciel",
          "error-not-found": "Vous êtes un élève ? '<br>' Vérifiez vos informations (prénom, nom et date de naissance) ou contactez un enseignant.'<br><br>' Vous êtes un enseignant ? '<br>' L‘accès à un parcours n‘est pas disponible pour le moment.",
          "first-title": "Rejoignez l'organisation { organizationName }",
          "invalid-reconciliation-error": "Une erreur est survenue. <br> Veuillez contacter le support.",
          "login-information-message": "Le compte Unisciel '<b>'{ connectionMethod }'</b>' va être associé à l'élève: '<b>'{ firstName } { lastName }'</b>'.'<br><br>'S’il s’agit bien de vous, cliquez sur \"Associer\", sinon déconnectez-vous.",
          "login-information-title": "Information de connexion",
          "subtitle": "Remplissez les informations manquantes"
        },
        "sup": {
          "error": "Veuillez vérifier les informations saisies, ou si vous avez déjà un compte Unisciel, connectez-vous avec celui-ci.",
          "fields": {
            "student-number": {
              "error": "Votre numéro étudiant n’est pas renseigné.",
              "label": "Numéro étudiant",
              "modify": "Modifier le numéro étudiant",
              "not-existing": "Je n'ai pas de numéro étudiant"
            }
          },
          "message": "Saisissez les informations telles qu’elles figurent sur votre carte d’étudiant pour accéder au test et envoyer les résultats. Unisciel s’en rappellera la prochaine fois.",
          "title": "Déclarez votre compte Unisciel auprès de {organizationName}."
        },
        "title": "Rejoindre"
      },
      "learning-more": {
        "info": "Ces liens vers les tutos ont été proposés par des utilisateurs Unisciel'.",
        "title": "Pour en apprendre davantage"
      },
      "levelup-notif": {
        "obtained-level": "Niveau { level } gagné !"
      },
      "login-or-register": {
        "invitation": "{ organizationName } vous invite à rejoindre Unisciel",
        "login-form": {
          "button": "Se connecter",
          "error": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects",
          "fields": {
            "login": {
              "label": "Adresse e-mail ou identifiant"
            },
            "password": {
              "label": "Mot de passe"
            }
          },
          "forgotten-password": {
            "email": "Une Adresse e-mail :",
            "instruction": "Mot de passe oublié ? Vous avez un compte Unisciel avec :",
            "other-identity": "Un identifiant : Contactez votre enseignant pour le réinitialiser",
            "reset-link": "Cliquez ici pour le réinitialiser"
          },
          "title": "J’ai déjà un compte Unisciel",
          "unexpected-user-account-error": "L'adresse e-mail ou l'identifiant est incorrect. Pour continuer, vous devez vous connecter à votre compte qui est sous la forme : "
        },
        "register-form": {
          "button": "S'inscrire",
          "button-form": "Je m'inscris",
          "error": "Une erreur est survenue. Veuillez recommencer ou contacter le support.",
          "fields": {
            "birthdate": {
              "day-error": "Votre jour de naissance n’est pas valide.",
              "label": "Date de naissance (JJ/MM/AAAA)",
              "month-error": "Votre mois de naissance n’est pas valide.",
              "year-error": "Votre année de naissance n’est pas valide."
            },
            "cgu": "J’accepte les '<a href=\"https://unisciel.fr/conditions-generales/\" class=\"link\" target=\"_blank\" rel=\"noopener noreferrer\">'conditions d’utilisation d’Unisciel'</a>'",
            "email": {
              "error": "Votre email n’est pas valide.",
              "help": "(ex: nom@exemple.fr)",
              "label": "Adresse e-mail"
            },
            "firstname": {
              "error": "Votre prénom n’est pas renseigné.",
              "label": "Prénom"
            },
            "lastname": {
              "error": "Votre nom n’est pas renseigné.",
              "label": "Nom"
            },
            "password": {
              "error": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre.",
              "help": "(8 caractères minimum, dont une majuscule, une minuscule et un chiffre)",
              "label": "Mot de passe",
              "show-button": "rendre le mot de passe lisible"
            },
            "username": {
              "label": "Mon identifiant"
            }
          },
          "legal-text": "Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Unisciel pour permettre l’accès au service offert. Elles sont conservées pendant la durée d’utilisation du service et sont destinées à Unisciel. Les résultats des tests pourront être communiqués à des tiers, avec votre consentement, dans le cas où vous avez été invité à suivre un parcours spécifique. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant le Délégué à la Protection des Données d’Unisciel à dpo@unisciel.fr.",
          "not-me": "Ce n'est pas moi",
          "options": {
            "email": "Mon adresse e-mail",
            "text": "Je m'inscris avec :",
            "username": "Mon identifiant"
          },
          "title": "Je m’inscris sur Unisciel"
        },
        "title": "Se connecter à Unisciel"
      },
      "not-connected": {
        "message": "Vous êtes bien déconnecté(e).'<br>'Merci, à bientôt.",
        "title": "Déconnecté"
      },
      "ordo-proposal": {
        "title": "Réponse"
      },
      "password-reset-demand": {
        "actions": {
          "back-home": "Retour à l'accueil",
          "back-sign-in": "Retour à la page de connexion",
          "reset": "Réinitialiser mon mot de passe"
        },
        "error": {
          "message": "Cette adresse e-mail ne correspond à aucun compte"
        },
        "fields": {
          "email": {
            "label": "Adresse e-mail"
          }
        },
        "page-title": "Oubli de mot de passe",
        "subtitle": "Entrez votre adresse e-mail ci-dessous",
        "succeed": {
          "help": "Si vous ne recevez pas cet e-mail, vérifiez vos courriers indésirables.",
          "instructions": "Un e-mail contenant la démarche à suivre afin de réinitialiser votre mot de passe\n vous a été envoyé à l’adresse e-mail {email}.",
          "subtitle": "Demande de réinitialisation de mot de passe"
        },
        "title": "Mot de passe oublié ?"
      },
      "profile": {
        "accessibility": {
          "title": "Votre profil Unisciel",
          "user-score": "Votre nombre de points Unisciel",
          "user-skills": "Vos compétences Unisciel"
        },
        "competence-card": {
          "congrats": "Bravo !",
          "details": "détails",
          "image-info": {
            "first-level": "Le premier niveau est en cours, complété à {percentageAheadOfNextLevel}%.",
            "level": "Niveau actuel: {currentLevel}. Le prochain niveau est complété à {percentageAheadOfNextLevel}%.",
            "no-level": "Compétence non commencée"
          }
        },
        "first-title": "Vous avez 14 compétences à tester. '<br>'On se concentre et c'est parti&nbsp;!",
        "resume-campaign-banner": {
          "accessibility": {
            "resume": "Continuer votre parcours",
            "share": "Partager les résultats de votre parcours"
          },
          "actions": {
            "continue": "Continuer",
            "resume": "Reprendre"
          },
          "reminder-continue-campaign": "Vous n'avez pas terminé votre parcours",
          "reminder-continue-campaign-with-title": "Vous n'avez pas terminé le parcours \"{title}\"",
          "reminder-send-campaign": "N'oubliez pas de finaliser votre envoi !",
          "reminder-send-campaign-with-title": "Parcours \"{title}\" terminé. N'oubliez pas de finaliser votre envoi !"
        },
        "title": "Compétences",
        "total-score-helper": {
          "explanation": "<p>C’est le nombre maximum de points Unisciel qu’on pourra atteindre lorsque les 8 niveaux du référentiel Unisciel seront disponibles.'</p><p>'Aujourd’hui, '<span class=\"hexagon-score-information__text--strong\">'le maximum est de 728 points'</span>'.'</p>'",
          "title": "Pourquoi 1024 points Unisciel ?"
        }
      },
      "profile-already-shared": {
        "actions": {
          "continue": "Continuez votre expérience Unisciel"
        },
        "explanation": "Vous avez déjà envoyé le profil ci-dessous à l'organisation {organization}'<br>'le {date} à {hour,time,hhmm}",
        "first-title": "Tout ne se passe pas comme prévu",
        "retry": {
          "button": "Renvoyer mon profil",
          "message": "{organization} vous invite à renvoyer votre profil pour mesurer votre progression."
        },
        "title": "Profil déjà envoyé"
      },
      "proposals": {
        "answer": {
          "qcu": "La réponse à la question est : "
        }
      },
      "reset-password": {
        "actions": {
          "sign-in": "Connectez-vous",
          "submit": "Envoyer"
        },
        "error": {
          "expired-demand": "Nous sommes désolés, mais votre demande de réinitialisation de mot de passe a déjà été utilisée ou est expirée. Merci de recommencer.",
          "forbidden": "Une erreur est survenue, veuillez contacter le support.",
          "wrong-format": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre."
        },
        "fields": {
          "password": {
            "label": "Mot de passe"
          }
        },
        "instruction": "Saisissez votre nouveau mot de passe",
        "succeed": "Votre mot de passe a été modifié avec succès.",
        "title": "Changer mon mot de passe"
      },
      "result-item": {
        "aband": "Pas de réponse",
        "actions": {
          "see-answers-and-tutorials": {
            "label": "Réponses et tutos"
          }
        },
        "ko": "Réponse incorrecte",
        "ok": "Réponse correcte",
        "partially": "Réponse partielle",
        "timedout": "Temps dépassé"
      },
      "send-profile": {
        "errors": {
          "archived": "L’envoi de votre profil n’est plus possible car l’organisateur a archivé la collecte de profils."
        },
        "first-title": "Envoi de votre profil Unisciel",
        "form": {
          "continue": "Continuez votre expérience Unisciel",
          "recipient": "Destinataire : {recipient}",
          "send": "J'envoie mon profil",
          "shared": "Merci, votre profil a bien été envoyé !"
        },
        "instructions": "Vous allez transmettre le score et les niveaux de compétence présents sur votre profil Unisciel. '<br>' Toute évolution de votre profil après cet envoi ne sera pas transmise. '<br>' Pensez à le vérifier avant de l'envoyer !",
        "title": "Envoyer mon profil"
      },
      "shared-certification": {
        "back-link": "Retour à la saisie du code de vérification",
        "title": "Partage de certificat"
      },
      "sign-in": {
        "actions": {
          "submit": "Je me connecte"
        },
        "error": {
          "message": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects"
        },
        "fields": {
          "legend": "Informations nécessaires pour se connecter.",
          "login": {
            "label": "Adresse e-mail ou identifiant"
          },
          "password": {
            "label": "Mot de passe"
          }
        },
        "first-title": "Connectez-vous",
        "forgotten-password": "Mot de passe oublié ?",
        "or": "OU",
        "pole-emploi": {
          "link": {
            "img": "pôle Emploi connect"
          },
          "title": "Se connecter avec pôle emploi"
        },
        "subtitle": {
          "link": "Créez un compte",
          "text": "Vous n'avez pas encore de compte Unisciel ?"
        },
        "title": "Connexion"
      },
      "sign-up": {
        "actions": {
          "submit": "Je m'inscris"
        },
        "fields": {
          "cgu": {
            "accept": "J'accepte les ",
            "and": "et la ",
            "cgu": "conditions d'utilisation d’Unisciel",
            "data-protection-policy": "politique de confidentialité",
            "error": "Vous devez accepter les conditions d’utilisation d’Unisciel pour créer un compte.",
            "label": "Accepter les conditions d’utilisation d’Unisciel",
            "pix": " d’Unisciel"
          },
          "email": {
            "error": "Votre adresse e-mail n’est pas valide.",
            "help": "(ex: nom@exemple.fr)",
            "label": "Adresse e-mail"
          },
          "firstname": {
            "error": "Votre prénom n’est pas renseigné.",
            "label": "Prénom"
          },
          "lastname": {
            "error": "Votre nom n’est pas renseigné.",
            "label": "Nom"
          },
          "legend": "Information nécessaire pour l'inscription.",
          "password": {
            "error": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre.",
            "help": "(8 caractères minimum, dont une majuscule, une minuscule et un chiffre)",
            "label": "Mot de passe"
          }
        },
        "first-title": "Inscrivez-vous",
        "instructions": "Les champs marqués de '<abbr title=\"obligatoire\" class=\"mandatory-mark\">'*'</abbr>' sont obligatoires",
        "legal-information": "Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Unisciel pour permettre l'accès au service offert par Unisciel. Elles sont conservées pendant une durée de 5 ans maximum à compter du dernier accès au compte utilisateur, et archivées conformément aux obligations pesant sur Unisciel. Elles sont destinées à Unisciel et à ses prestataires techniques exclusivement. Vous pouvez exercer vos droits \"informatique et libertés\" auprès de dpo@univ-lille.fr. En savoir plus sur la gestion de vos données et vos droits (lien vers la politique de confidentialité).",
        "subtitle": {
          "link": "connectez-vous à votre compte"
        },
        "title": "Inscription"
      },
      "sitemap": {
        "accessibility": {
          "help": "Aide pour répondre à vos questions sur l'accessibilité sur Unisciel",
          "title": "Accessibilité"
        },
        "cgu": {
          "policy": "Politique de protection des données",
          "subcontractors": "Liste des sous-traitants"
        },
        "resources": "Ressources",
        "title": "Plan du site"
      },
      "skill-review": {
        "abstract": "Vous maîtrisez '<strong>'{rate, number, ::percent}'</strong><br>'des compétences testées.",
        "abstract-title": "Votre résultat pour ce parcours",
        "actions": {
          "continue": "Continuez votre expérience Unisciel",
          "improve": "Je retente",
          "send": "J'envoie mes résultats"
        },
        "already-shared": "Merci, vos résultats ont bien été envoyés !",
        "archived": "Ce parcours a été archivé par l'organisateur.'<br>' L'envoi des résultats n'est plus possible.",
        "badge-card": {
          "acquired": "Obtenu",
          "not-acquired": "Non obtenu"
        },
        "badges-title": "Vos résultats thématiques",
        "details": {
          "header-result": "Résultats",
          "header-skill": "Compétences testées",
          "percentage": "{rate, number, ::percent}",
          "result": "Résultat global",
          "result-by-skill": "Votre résultat pour la compétence",
          "title": "Vos résultats par compétence"
        },
        "improve": {
          "description": "Vous pouvez retenter certaines questions",
          "title": "Envie d'améliorer vos résultats ?"
        },
        "information": "Si vous avez déjà effectué des parcours sur Unisciel, les questions auxquelles vous aviez répondu ne vous ont pas été posées de nouveau. En revanche, le résultat affiché ici tient compte de l’ensemble de vos réponses.",
        "net-promoter-score": {
          "link": {
            "href": "https://unisciel.fr",
            "label": "Je donne mon avis"
          },
          "title": "Votre avis compte ! Prenez quelques minutes pour nous dire ce que vous avez pensé de ce test et nous aider à l'améliorer."
        },
        "not-finished": "Vous ne pouvez pas encore envoyer vos résultats, nous avons encore quelques questions à vous poser.",
        "organization-message": "Message de votre organisation",
        "retry": {
          "button": "Repasser mon parcours",
          "message": "{organizationName} vous invite à repasser ce parcours afin d'améliorer votre résultat et de continuer à progresser."
        },
        "send-results": "N'oubliez pas d'envoyer vos résultats à l'organisateur du parcours.",
        "send-status": {
          "in-progress": "Envoi en cours"
        },
        "send-title": "Envoyez vos résultats",
        "stage": {
          "masteryPercentage": "{rate, number, ::percent} de réussite",
          "starsAcquired": "{acquired, plural, =0 {aucune étoile acquise} other {# étoiles acquises}} sur {total}"
        },
        "title": "Résultat"
      },
      "terms-of-service": {
        "cgu": "J'accepte les '<a href=\"https://unisciel.fr/conditions-generales/\" class=\"link\" target=\"_blank\">'conditions d'utilisation d'Unisciel'</a>'",
        "form": {
          "button": "Je continue",
          "error-message": "Vous devez accepter les conditions d’utilisation d’Unisciel."
        },
        "message": "Nous avons mis à jour nos conditions d'utilisation. Veuillez les accepter afin de pouvoir continuer votre expérience sur Unisciel.",
        "title": "Conditions d'utilisation"
      },
      "terms-of-service-pe": {
        "cgu": "J'accepte les '<a href=\"https://unisciel.fr/conditions-generales/\" class=\"link\" target=\"_blank\">'conditions d'utilisation d’Unisciel'</a>'",
        "form": {
          "button": "Je continue",
          "error-message": "Vous devez accepter les conditions d’utilisation d’Unisciel.",
          "expired-authentication-key": "Votre demande d'authentification a expirée, merci de renouveler votre connexion en cliquant sur le bouton retour"
        },
        "message": "Votre compte Unisciel est sur le point d’être créé à partir des éléments transmis par Pôle Emploi.",
        "title": "Conditions d'utilisation"
      },
      "timed-challenge-instructions": {
        "action": "Commencer",
        "primary": "Vous disposerez de '<span class=\"timed-challenge-instructions__time\">'{time}'</span>' pour réussir la question suivante.",
        "secondary": "Vous pourrez continuer à répondre ensuite, mais la question ne sera pas considérée comme réussie."
      },
      "tutorial": {
        "next": "Suivant",
        "pages": {
          "page0": {
            "explanation": "En fonction de vos réponses,\nUnisciel adapte la difficulté des questions.",
            "icon": "icn-algo.svg",
            "title": "Un niveau de difficulté adapté"
          },
          "page1": {
            "explanation": "Des recommandations adaptées\n à votre niveau réel vous seront proposées.",
            "icon": "icn-tutos.svg",
            "title": "Des explications et ressources pour progresser"
          },
          "page2": {
            "explanation": "Votre profil Unisciel évolue avec vous,\n vous pourrez améliorer vos résultats par la suite.",
            "icon": "icn-recherche.svg",
            "title": "Répondez sans aide extérieure"
          },
          "page3": {
            "explanation": "Basé sur vos résponse, \nUnisciel adapte la difficulté des questions",
            "icon": "icn-algo.svg",
            "title": "Difficulté adapté"
          },
          "page4": {
            "explanation": "En fonction de vos réponses,\nUnisciel adapte la difficulté des questions.",
            "icon": "icn-algo.svg",
            "title": "Un niveau de difficulté adapté"
          }
        },
        "pass": "Ignorer",
        "start": "Démarrer mon parcours",
        "title": "Didacticiel de la campagne"
      },
      "tutorial-panel": {
        "info": "Ces liens vers les tutos ont été proposés par Unisciel.",
        "title": "Pour comprendre"
      },
      "update-expired-password": {
        "button": "Réinitialiser",
        "fields": {
          "error": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre.",
          "help": "(8 caractères minimum, dont une majuscule, une minuscule et un chiffre)",
          "label": "Mot de passe"
        },
        "first-title": "Réinitialiser le mot de passe",
        "go-to-login": "Je me connecte",
        "subtitle": "Choisissez un nouveau mot de passe pour continuer",
        "title": "Changer mon mot de passe",
        "validation": "Votre mot de passe a été mis à jour."
      },
      "user-account": {
        "account-update-email": {
          "email-information": "Cette adresse e-mail doit être valide en cas de mot de passe oublié. '<br>' Elle sera votre nouvelle adresse de connexion.",
          "fields": {
            "errors": {
              "empty-password": "Votre mot de passe ne peut pas être vide.",
              "invalid-password": "Le mot de passe que vous avez saisi est invalide.",
              "mismatching-email": "Les deux adresses e-mail ne sont pas identiques. Veuillez vérifier votre saisie.",
              "new-email-already-exist": "Cette adresse e-mail est déjà utilisée.",
              "unknown-error": "Une erreur est survenue. Veuillez recommencer ou contacter le support.",
              "wrong-email-format": "Votre adresse e-mail n’est pas valide."
            },
            "new-email": {
              "label": "Nouvelle adresse e-mail"
            },
            "new-email-confirmation": {
              "label": "Confirmation de l'adresse e-mail"
            },
            "password": {
              "label": "Mot de passe"
            }
          },
          "password-information": "Saisissez votre mot de passe pour confirmer",
          "save-button": "Confirmer",
          "title": "Modification de votre adresse e-mail"
        },
        "account-update-email-with-validation": {
          "fields": {
            "errors": {
              "empty-password": "Votre mot de passe ne peut pas être vide.",
              "invalid-email": "Votre adresse e-mail n’est pas valide.",
              "invalid-password": "Le mot de passe que vous avez saisi est invalide.",
              "new-email-already-exist": "Cette adresse e-mail est déjà utilisée."
            },
            "new-email": {
              "label": "Nouvelle adresse e-mail"
            },
            "password": {
              "label": "Mot de passe",
              "security-information": "La sécurité de vos informations personnelles est essentielle. Nous vérifions donc que vous êtes bien à l'origine de cette demande. Saisissez votre mot de passe pour recevoir un code de vérification."
            }
          },
          "save-button": "Recevoir un code de vérification",
          "title": "Modification de votre adresse e-mail"
        },
        "connexion-methods": {
          "authentication-methods": {
            "gar": "via ENT/Mediacentre",
            "label": "Connexion externe",
            "pole-emploi": "via Pôle Emploi"
          },
          "edit-button": "Modifier",
          "email": "Adresse e-mail",
          "menu-link-title": "Mes méthodes de connexion",
          "username": "Identifiant"
        },
        "email-verification": {
          "code-explanation-of-use": "Pour se déplacer de champ en champ, utilisez soit la tabulation, soit les flèches gauche et droite du clavier. Pour remplir un champ, saisissez des chiffres de 1 à 9 ou bien utilisez les flèches haut et bas du clavier.",
          "code-label": "Champ",
          "code-legend": "Saisir le code de vérification envoyé par email",
          "confirmation-message": "E-mail envoyé !",
          "description": "Saisissez le code de vérification reçu à l'adresse e-mail :",
          "did-not-receive": "Je n'ai rien reçu,",
          "errors": {
            "email-modification-demand-expired": "Ce code a expiré. Veuillez renouveler la demande.",
            "incorrect-code": "Le code saisi est incorrect.",
            "new-email-already-exist": "Cette adresse e-mail est déjà utilisée.",
            "unknown-error": "Une erreur est survenue. Veuillez recommencer ou contacter le support."
          },
          "send-back-the-code": "me renvoyer le code",
          "title": "Vérification de votre adresse e-mail",
          "update-successful": "Votre adresse e-mail a été changée !"
        },
        "language": {
          "fields": {
            "select": {
              "labels": {
                "english": "Anglais",
                "french": "Français"
              }
            }
          },
          "lang": "Langue",
          "menu-link-title": "Choisir ma langue"
        },
        "personal-information": {
          "first-name": "Prénom",
          "last-name": "Nom",
          "menu-link-title": "Mes informations personnelles"
        },
        "title": "Mon compte"
      },
      "user-tests": {
        "title": "Mes parcours"
      },
      "user-tutorials": {
        "description": "Progresser grâce aux tutos suggérés par la communauté des utilisateurs d’Unisciel.",
        "empty-list-info": {
          "description": {
            "part1": "Au fil de vos tests, des tutos vous sont recommandés : cliquez sur l'icône marque page",
            "part2": "pour enregistrer ceux que vous souhaitez retrouver ici !"
          },
          "image-link": "images/illustrations/user-tutorials/illustration-fr.svg",
          "title": "Vous n'avez encore rien enregistré"
        },
        "label": "tutoriels",
        "list": {
          "title": "Ma liste",
          "tutorial": {
            "actions": {
              "evaluate": {
                "extra-information": "Donner mon avis sur ce tuto",
                "label": "Tuto utile"
              },
              "remove": {
                "extra-information": "Retirer de ma liste de tutos",
                "label": "Retirer"
              },
              "save": {
                "extra-information": "Enregistrer dans ma liste de tutos",
                "label": "Enregistrer"
              }
            },
            "source": "Par"
          }
        },
        "title": "Mes tutos"
      }
    }
  }]];
  _exports.default = _default;
});